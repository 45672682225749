import React from "react";
import { Pathname } from "../Pathname";
const IdCard = React.lazy(() => import("../../pages/IdCards/IdCards"));
const Dashboard = React.lazy(() => import("../../pages/Dashboard/Dashboard"));
const Components = React.lazy(() =>
import("../../pages/Components/Components")
);
const ComponentsCerti = React.lazy(() => import ("../../pages/ComponentsCerti/ComponentsCerti"));
const IssueDocument = React.lazy(() =>
import("../../pages/IssueDocument/IssueDocument")
);

const IssueCertificate = React.lazy(() => import("../../pages/IssueCertificate/IssueCertificate"));
const PageNotFound = React.lazy(() =>
    import("../../pages/PageNotFound/PageNotFound")
);
const ListDocument = React.lazy(() => import("../../pages/ListDocument/ListDocument"));
const LandingPage = React.lazy(() => import("../../pages/LandingPage/LandingPage"));
const CertificatePage = React.lazy(() => import("../../pages/CertificatePage/CertificatePage"));

const routes = [
    {
        path: Pathname.IdCards,
        component: <IdCard />,
        exact: true,
        key: "Id-page",
    },
    {
        path: Pathname.CertificatePage,
        component: <CertificatePage />,
        exact: true,
        key: "Certificate-page",
    },
    {
        path: Pathname.DASHBOARD,
        component: <Dashboard />,
        exact: true,
        key: "dashboard-page",
    },
    {
        path: Pathname.COMPONENTS,
        component: <Components />,
        exact: true,
        key: "components-page",
    },
    {
        path: Pathname.COMPONENTSCERTI,
        component: <ComponentsCerti />,
        exact: true,
        key: "componentsCerti-page",
    },
    {
        path: Pathname.ISSUE_DOCUMENTS,
        component: <IssueDocument />,
        exact: true,
        key: "issue-document-page",
    },
    {
        path: Pathname.ISSUE_CERTIFICATE,
        component: <IssueCertificate />,
        exact: true,
        key: "issue-certificate-page",
    },
    {
        path: Pathname.LIST_DOCUMENTS,
        component: <ListDocument />,
        exact: true,
        key: "list-document-page",
    },
    {
        path: Pathname.LANDING_PAGE,
        component: <LandingPage />,
        exact: true,
        key: "landing-page",
    },
    {
        path: Pathname.PAGE_NOT_FOUND,
        component: <PageNotFound />,
        key: "page-not-found",
    },
];
export { routes };
