import React, { useEffect, useState } from "react";
import { ColorPicker, Typography } from "../../index";
import "./BackgroundController.scss";

const BackgroundController = React.forwardRef((props, ref) => {
  const [background, setBackground] = useState("");

  useEffect(() => {
    if (ref) {
      ref.style.background = background;
      ref.style.border = "none";
    }
  }, [background]);

  const handleBackgroundController = (color) => {
    setBackground(color);
  };

  return (
    <div className="BackgroundController">
      <Typography className="TableController--Title" color="#004B85">
        Background Fill
      </Typography>
      <ColorPicker onColorChange={handleBackgroundController} />
    </div>
  );
});

export default BackgroundController;
