import React, { useEffect, useState } from 'react';
import './FontStyle.scss';

const textDecorationStyleList = ['solid', 'double', 'dotted', 'dashed', 'wavy'];

const FontStyle = React.forwardRef((props, ref) => {
    const [isBold, setIsBold] = useState(false);
    const [isItalic, setIsItalic] = useState(false);
    const [isUnderline, setIsUnderline] = useState(false);
    const [underLineStyle, setUnderLineStyle] = useState(textDecorationStyleList[0]);

    const [isTextDecorationStyleOpen, setIsTextDecorationStyleOpen] = useState(false);

    useEffect(() => {
        setIsBold(!!ref?.style?.fontWeight);
        setIsUnderline(!!ref?.style?.textDecoration);
        setIsItalic(!!ref?.style?.fontStyle);

        ref?.style?.textDecoration
            ? setIsTextDecorationStyleOpen(true)
            : setIsTextDecorationStyleOpen(false);
        ref?.style?.textDecorationStyle
            ? setUnderLineStyle(ref?.style?.textDecorationStyle)
            : setUnderLineStyle(underLineStyle);
    }, [ref]);

    const handleBold = () => {
        if (!ref) return;
        ref.style.fontWeight = isBold ? '' : 'bold';
        setIsBold((prev) => !prev);
    };

    const handleItalic = () => {
        if (!ref) return;
        ref.style.fontStyle = isItalic ? '' : 'italic';
        setIsItalic((prev) => !prev);
    };

    const handleUnderline = () => {
        if (!ref) return;
        console.log('UNDERLINE', ref);

        ref.style.textDecoration = isUnderline ? '' : `underline`;
        console.log('TEXT DECORATION ', ref.style.textDecoration);
        ref.style.textDecorationStyle = ` ${underLineStyle}`;

        setIsUnderline((prev) => !prev);

        setIsTextDecorationStyleOpen(!isTextDecorationStyleOpen);
    };

    const handleUnderLineProperty = (item) => {
        return (ref.style.textDecorationStyle = `${item}`);
    };

    const isActiveTab = (isSelected, className) =>
        isSelected ? `${className} selected` : className;

    const selectedUnderLineStyle = (item, className) => {
        return item === underLineStyle ? `${className} Active` : `${className}`;
    };

    useEffect(() => {
        if (ref?.style?.textDecoration) {
            setIsTextDecorationStyleOpen(false);
        }
    }, [ref]);
    return (
        <React.Fragment>
            <div className="FontStyle">
                <div
                    onClick={handleBold}
                    className={isActiveTab(isBold, 'FontStyle--Bold')}
                >
                    B
                </div>
                <div
                    onClick={handleUnderline}
                    className={isActiveTab(isUnderline, 'FontStyle--Underline')}
                >
                    U
                </div>
                <div
                    onClick={handleItalic}
                    className={isActiveTab(isItalic, 'FontStyle--Italic')}
                >
                    I
                </div>
            </div>
            {isTextDecorationStyleOpen && (
                <div className="UnderlineStyle">
                    <div className="UnderlineStyle--label">
                        <h3>
                            Underline Style :<span> {underLineStyle}</span>
                        </h3>
                    </div>
                    <div className="UnderlineStyle--Properties">
                        {textDecorationStyleList.map((item, index) => (
                            <div
                                className={selectedUnderLineStyle(
                                    item,
                                    'UnderlineStyle--Property'
                                )}
                                key={index}
                                onClick={() => {
                                    setUnderLineStyle(item);
                                    setIsTextDecorationStyleOpen(
                                        !isTextDecorationStyleOpen
                                    );
                                    return handleUnderLineProperty(item);
                                }}
                            >
                                <option value={item}>{item}</option>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </React.Fragment>
    );
});

export default FontStyle;
