import React, { useState } from 'react';
import { Box, Button, Input, Typography } from '../../common';

import './NameController.scss';

const NameController = ({ onCertificateNameChange }) => {
  const [certificateName, setCertificateName] = useState('');

  const handleSave = () => {
    if (certificateName.trim() !== '') {
      console.log("Saving certificate name:", certificateName.trim());
      onCertificateNameChange(certificateName.trim());
    //  setCertificateName('');
    }
  };

  const handleInputChange = (event) => {
    setCertificateName(event.target.value);
  };

  return (
    <React.Fragment>
      <div className="NameController--Title">
        <Typography>Name the Certificate</Typography>
      </div>

      <Box className='NameController'>
        <div className='NameController--Box'>
          <Input
            placeholder="Certificate Name"
            value={certificateName}
            onChange={handleInputChange}
            className="NameController--InputField"
          />
          <Button 
            onClick={handleSave}
          >
            +
          </Button>
        </div>
      </Box>
    </React.Fragment>
  );
};

export default NameController;
