import React, { useState } from 'react';
import {
    AlignCenter,
    AlignJustify,
    AlignLeft,
    AlignRight,
} from '../../../assets/icons/index';
import './FontAlignment.scss';

const LEFT = 'left';
const RIGHT = 'right';
const CENTER = 'center';

const FontAlignment = React.forwardRef((props, ref) => {
    const [alignment, setAlignment] = useState('');

    const handleFontAlignment = (align) => {
        setAlignment(align);
        ref.style.textAlign = align;
    };

    return (
        <div className="FontAlignment">
            <div
                className={
                    alignment === CENTER
                        ? 'FontAlignment--BorderRight FontAlignment--Selected'
                        : 'FontAlignment--BorderRight'
                }
                style={
                    ref instanceof HTMLTextAreaElement ? {} : { pointerEvents: 'none' }
                }
                onClick={() => handleFontAlignment(CENTER)}
            >
                <AlignCenter />
            </div>
            <div
                className={
                    alignment === RIGHT
                        ? 'FontAlignment--BorderRight FontAlignment--Selected'
                        : 'FontAlignment--BorderRight'
                }
                style={
                    ref instanceof HTMLTextAreaElement ? {} : { pointerEvents: 'none' }
                }
                onClick={() => handleFontAlignment(RIGHT)}
            >
                <AlignRight />
            </div>
            <div
                className={
                    alignment === LEFT
                        ? 'FontAlignment--BorderRight FontAlignment--Selected'
                        : 'FontAlignment--BorderRight'
                }
                style={
                    ref instanceof HTMLTextAreaElement ? {} : { pointerEvents: 'none' }
                }
                onClick={() => handleFontAlignment(LEFT)}
            >
                <AlignLeft />
            </div>
        </div>
    );
});

export default FontAlignment;
