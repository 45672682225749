import { Suspense } from 'react';
import { ReactQueryDevtools } from 'react-query/devtools';
import { QueryClientProvider, QueryClient } from 'react-query';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ErrorBoundary, Loader } from './components';
import { routes } from './router';
import './App.scss';

const App = () => {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
            },
        },
    });

    return (
        <ErrorBoundary>
            <QueryClientProvider client={queryClient}>
                <Suspense fallback={<Loader />}>
                    <Router>
                        <Routes>
                            {routes.map((route) => {
                                return (
                                    <Route
                                        key={route.key}
                                        path={route.path}
                                        exact={route.exact === true}
                                        element={route.component}
                                    />
                                );
                            })}
                        </Routes>
                    </Router>
                </Suspense>
                <ReactQueryDevtools initialIsOpen={false} position={'bottom-right'} />
            </QueryClientProvider>
        </ErrorBoundary>
    );
};

export default App;
