import React from "react";
import "./ReportCardFields.scss";
const ReportCardFields = () => {
    return (
        <div className="ReportCardFields">
            <h1>ReportCardFields</h1>
        </div>
    );
};

export default ReportCardFields;
