const fontFamilies = [
    "Roboto",
    "Sans-Serif",
    "Monospace",
    "Arial",
    "Verdana",
    "Tahoma",
    "Calibri",
    "Times New Roman",
    "Georgia",
    "Garamond",
    "Baskerville",
    "Comic Sans MS",
    "Brush Script MT",
    "Zapfino",
    "Freestyle Script",
    "Edwardian Script ITC",
    "Courier",
    "Courier New",
    "Lucida Console",
    "Consolas",
    "Impact",
    "Jokerman",
    "Chiller",
    "Curlz MT",
    "Stencil",
    "Open Sans",
    "Montserrat",
    "Lato",
    "Raleway",
];

const FontFamily = fontFamilies.sort((fontFamilyA, fontFamilyB) =>
    fontFamilyA.localeCompare(fontFamilyB)
);

export default FontFamily;
