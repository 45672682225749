import React from "react";

const Copy = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.186"
      height="17.568"
      viewBox="0 0 15.186 17.568"
    >
      <g
        id="Group_46611"
        data-name="Group 46611"
        transform="translate(523.229 -407.786)"
      >
        <path
          id="Path_44679"
          data-name="Path 44679"
          d="M-523.229,446.98q0-3.044,0-6.088a1.362,1.362,0,0,1,1.136-1.393,1.338,1.338,0,0,1,.261-.017h9.526a1.38,1.38,0,0,1,1.424,1.418q0,6.082,0,12.164a1.352,1.352,0,0,1-1.323,1.391c-.445.018-.892.007-1.338.007h-8.214a1.386,1.386,0,0,1-1.425-1,1.55,1.55,0,0,1-.049-.441Q-523.229,450-523.229,446.98Zm1.033-.013q0,2.808,0,5.617a.8.8,0,0,0,.838.839h8.622a.794.794,0,0,0,.824-.828c-.011-3.749.027-7.5-.021-11.246a.8.8,0,0,0-.841-.837h-8.6a.8.8,0,0,0-.827.826Q-522.2,444.152-522.2,446.967Z"
          transform="translate(0 -29.109)"
          fill="#1e89fd"
        />
        <path
          id="Path_44680"
          data-name="Path 44680"
          d="M-476.113,415.3q0,3.031,0,6.063a1.349,1.349,0,0,1-1.141,1.387,6.546,6.546,0,0,1-1,.022c-.053,0-.052-.031-.052-.068,0-.3,0-.595,0-.892,0-.058.02-.073.075-.073a2.341,2.341,0,0,0,.406-.012.791.791,0,0,0,.675-.808q0-1.955,0-3.911,0-3.176,0-6.351c0-.363-.011-.726-.023-1.089a.789.789,0,0,0-.736-.737c-.048,0-.1,0-.144,0h-8.477a.8.8,0,0,0-.9.827c0,.062-.027.069-.079.069-.293,0-.586,0-.879,0-.04,0-.079,0-.077-.057a3.766,3.766,0,0,1,.045-.861,1.379,1.379,0,0,1,1.389-1.017q2.624,0,5.249,0h4.239a1.392,1.392,0,0,1,1.426,1.41Q-476.111,412.247-476.113,415.3Z"
          transform="translate(-31.93)"
          fill="#1e89fd"
        />
        <path
          id="Path_44681"
          data-name="Path 44681"
          d="M-510.56,458.583q0-2.815,0-5.63a.8.8,0,0,1,.827-.826h8.6a.8.8,0,0,1,.841.837c.047,3.749.009,7.5.021,11.246a.794.794,0,0,1-.824.828h-8.622a.8.8,0,0,1-.838-.839Q-510.56,461.392-510.56,458.583Z"
          transform="translate(-11.636 -40.726)"
          fill="#fff"
        />
        <path
          id="Path_44682"
          data-name="Path 44682"
          d="M-488.331,573.8h3.187a.4.4,0,0,1,.388.219.391.391,0,0,1-.254.567.711.711,0,0,1-.168.018h-6.309a.422.422,0,0,1-.464-.335.408.408,0,0,1,.432-.47q1.2,0,2.4,0h.787Z"
          transform="translate(-28.722 -152.478)"
          fill="#1e89fd"
        />
        <path
          id="Path_44683"
          data-name="Path 44683"
          d="M-488.305,502.658q1.594,0,3.187,0a.4.4,0,0,1,.4.558.407.407,0,0,1-.411.246h-6.348a.41.41,0,0,1-.453-.373.4.4,0,0,1,.435-.43Z"
          transform="translate(-28.748 -87.135)"
          fill="#1e89fd"
        />
        <path
          id="Path_44684"
          data-name="Path 44684"
          d="M-488.343,527.213q-1.6,0-3.2,0a.394.394,0,0,1-.393-.549.394.394,0,0,1,.4-.245c.861,0,1.722,0,2.583,0q1.908,0,3.816,0a.4.4,0,0,1,.391.545.4.4,0,0,1-.406.248Z"
          transform="translate(-28.709 -108.958)"
          fill="#1e89fd"
        />
        <path
          id="Path_44685"
          data-name="Path 44685"
          d="M-488.328,550.93q-1.6,0-3.2,0a.4.4,0,0,1-.389-.564.376.376,0,0,1,.312-.228,1,1,0,0,1,.118,0q3.154,0,6.308,0a.421.421,0,0,1,.438.241.4.4,0,0,1-.387.557c-.708,0-1.416,0-2.125,0Z"
          transform="translate(-28.723 -130.737)"
          fill="#1e89fd"
        />
        <path
          id="Path_44686"
          data-name="Path 44686"
          d="M-488.316,479.787H-491.5a.394.394,0,0,1-.4-.274.392.392,0,0,1,.125-.436.467.467,0,0,1,.32-.089h6.3a.585.585,0,0,1,.232.028.4.4,0,0,1,.249.431.405.405,0,0,1-.379.337c-.162.005-.323,0-.485,0Z"
          transform="translate(-28.75 -65.396)"
          fill="#1e89fd"
        />
      </g>
    </svg>
  );
};

export default Copy;
