import React from "react";

const Padding = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="208"
      height="158"
      viewBox="0 0 208 158"
    >
      <g
        id="Group_46599"
        data-name="Group 46599"
        transform="translate(-12 -404.992)"
      >
        <g
          id="Rectangle_5710"
          data-name="Rectangle 5710"
          transform="translate(71 439.992)"
          fill="#fff"
          stroke="#eceff3"
          stroke-width="1"
        >
          <rect width="89" height="89" rx="6" stroke="none" />
          <rect x="0.5" y="0.5" width="88" height="88" rx="5.5" fill="none" />
        </g>
        <g
          id="Rectangle_5711"
          data-name="Rectangle 5711"
          transform="translate(89 457.992)"
          fill="#e6f1ff"
          stroke="#9aaec7"
          stroke-width="1"
        >
          <rect width="53" height="53" rx="6" stroke="none" />
          <rect x="0.5" y="0.5" width="52" height="52" rx="5.5" fill="none" />
        </g>
        <g
          id="Rectangle_5712"
          data-name="Rectangle 5712"
          transform="translate(103 472.992)"
          fill="#fff"
          stroke="#abcefc"
          stroke-width="1"
        >
          <rect width="24" height="24" rx="4" stroke="none" />
          <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" fill="none" />
        </g>

        <g
          id="Group_46590"
          data-name="Group 46590"
          transform="translate(-3568 -1034.008)"
        ></g>
        <g
          id="Group_46582"
          data-name="Group 46582"
          transform="translate(-3588 -1036.008)"
        >
          <line
            id="Line_255"
            data-name="Line 255"
            y2="10"
            transform="translate(3703.5 1480.5)"
            fill="none"
            stroke="#dbdee2"
            stroke-width="1"
          />
          <line
            id="Line_256"
            data-name="Line 256"
            x2="4"
            transform="translate(3701.5 1480.5)"
            fill="none"
            stroke="#dbdee2"
            stroke-width="1"
          />
          <line
            id="Line_257"
            data-name="Line 257"
            x2="4"
            transform="translate(3701.5 1490.5)"
            fill="none"
            stroke="#dbdee2"
            stroke-width="1"
          />
          
        </g>
        <g
          id="Group_46586"
          data-name="Group 46586"
          transform="translate(-3588.5 -1019.008)"
        >
          <line
            id="Line_255-2"
            data-name="Line 255"
            y2="10"
            transform="translate(3703.5 1480.5)"
            fill="none"
            stroke="#abcefc"
            stroke-width="1"
          />
          <line
            id="Line_256-2"
            data-name="Line 256"
            x2="4"
            transform="translate(3701.5 1480.5)"
            fill="none"
            stroke="#abcefc"
            stroke-width="1"
          />
          <line
            id="Line_257-2"
            data-name="Line 257"
            x2="4"
            transform="translate(3701.5 1490.5)"
            fill="none"
            stroke="#abcefc"
            stroke-width="1"
          />
        </g>
        <g
          id="Group_46587"
          data-name="Group 46587"
          transform="translate(-3588.5 -983.008)"
        >
          <line
            id="Line_255-3"
            data-name="Line 255"
            y2="10"
            transform="translate(3703.5 1480.5)"
            fill="none"
            stroke="#abcefc"
            stroke-width="1"
          />
          <line
            id="Line_256-3"
            data-name="Line 256"
            x2="4"
            transform="translate(3701.5 1480.5)"
            fill="none"
            stroke="#abcefc"
            stroke-width="1"
          />
          <line
            id="Line_257-3"
            data-name="Line 257"
            x2="4"
            transform="translate(3701.5 1490.5)"
            fill="none"
            stroke="#abcefc"
            stroke-width="1"
          />
        </g>
        <g
          id="Group_46588"
          data-name="Group 46588"
          transform="translate(1582.5 -3219.008) rotate(90)"
        >
          <line
            id="Line_255-4"
            data-name="Line 255"
            y2="10"
            transform="translate(3703.5 1480.5)"
            fill="none"
            stroke="#abcefc"
            stroke-width="1"
          />
          <line
            id="Line_256-4"
            data-name="Line 256"
            x2="4"
            transform="translate(3701.5 1480.5)"
            fill="none"
            stroke="#abcefc"
            stroke-width="1"
          />
          <line
            id="Line_257-4"
            data-name="Line 257"
            x2="4"
            transform="translate(3701.5 1490.5)"
            fill="none"
            stroke="#abcefc"
            stroke-width="1"
          />
        </g>
        <g
          id="Group_46589"
          data-name="Group 46589"
          transform="translate(1618.5 -3219.008) rotate(90)"
        >
          <line
            id="Line_255-5"
            data-name="Line 255"
            y2="10"
            transform="translate(3703.5 1480.5)"
            fill="none"
            stroke="#abcefc"
            stroke-width="1"
          />
          <line
            id="Line_256-5"
            data-name="Line 256"
            x2="4"
            transform="translate(3701.5 1480.5)"
            fill="none"
            stroke="#abcefc"
            stroke-width="1"
          />
          <line
            id="Line_257-5"
            data-name="Line 257"
            x2="4"
            transform="translate(3701.5 1490.5)"
            fill="none"
            stroke="#abcefc"
            stroke-width="1"
          />
        </g>
        <g
          id="Group_46583"
          data-name="Group 46583"
          transform="translate(-3588 -966.008)"
        >
          <line
            id="Line_255-6"
            data-name="Line 255"
            y2="10"
            transform="translate(3703.5 1480.5)"
            fill="none"
            stroke="#dbdee2"
            stroke-width="1"
          />
          <line
            id="Line_256-6"
            data-name="Line 256"
            x2="4"
            transform="translate(3701.5 1480.5)"
            fill="none"
            stroke="#dbdee2"
            stroke-width="1"
          />
          <line
            id="Line_257-6"
            data-name="Line 257"
            x2="4"
            transform="translate(3701.5 1490.5)"
            fill="none"
            stroke="#dbdee2"
            stroke-width="1"
          />
        </g>
        <g
          id="Group_46584"
          data-name="Group 46584"
          transform="translate(1566 -3219.008) rotate(90)"
        >
          <line
            id="Line_255-7"
            data-name="Line 255"
            y2="10"
            transform="translate(3703.5 1480.5)"
            fill="none"
            stroke="#dbdee2"
            stroke-width="1"
          />
          <line
            id="Line_256-7"
            data-name="Line 256"
            x2="4"
            transform="translate(3701.5 1480.5)"
            fill="none"
            stroke="#dbdee2"
            stroke-width="1"
          />
          <line
            id="Line_257-7"
            data-name="Line 257"
            x2="4"
            transform="translate(3701.5 1490.5)"
            fill="none"
            stroke="#dbdee2"
            stroke-width="1"
          />
        </g>
        <g
          id="Group_46585"
          data-name="Group 46585"
          transform="translate(1636 -3219.008) rotate(90)"
        >
          <line
            id="Line_255-8"
            data-name="Line 255"
            y2="10"
            transform="translate(3703.5 1480.5)"
            fill="none"
            stroke="#dbdee2"
            stroke-width="1"
          />
          <line
            id="Line_256-8"
            data-name="Line 256"
            x2="4"
            transform="translate(3701.5 1480.5)"
            fill="none"
            stroke="#dbdee2"
            stroke-width="1"
          />
          <line
            id="Line_257-8"
            data-name="Line 257"
            x2="4"
            transform="translate(3701.5 1490.5)"
            fill="none"
            stroke="#dbdee2"
            stroke-width="1"
          />
        </g>
      </g>
    </svg>
  );
};

export default Padding;
