import React from "react";

const DownArrow = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="9.446"
            height="5.646"
            viewBox="0 0 9.446 5.646"
        >
            <g id="download" transform="translate(0.056 0.556)">
                <path
                    id="Expand_More"
                    d="M.717.124l3.45,3.451L7.617.123a.421.421,0,1,1,.6.6L4.465,4.467h0a.42.42,0,0,1-.595,0L.123.719a.42.42,0,1,1,.594-.6Z"
                    transform="translate(0.5 0)"
                    fill="#9a9b9c"
                    stroke="#3f3f40"
                    strokeWidth="1"
                />
            </g>
        </svg>
    );
};

export default DownArrow;
