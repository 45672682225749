import React, { useState, useEffect } from "react";
import { Dropdown, Typography } from "../../common";
import { PredefinedLineHeights } from "../../../constants";
import "./LineHeightController.scss";

const LineHeightController = React.forwardRef((props, ref) => {
    const { textAreaRef } = props;
    const TextArea = textAreaRef.current;
    const [lineHeight, setLineHeight] = useState();

    const handleSelectLineHeight = (size) => {
        setLineHeight(size);
        ref.style.lineHeight = size;
    };

    useEffect(() => {
        if (ref && TextArea) {
            setLineHeight(ref.style.lineHeight);
        }
    }, [ref, TextArea]);

    return (
        <div className="LineHeightController">
            <Typography className="LineHeightController--Title" color="#004B85">
                Line Height
            </Typography>
            <div className="LineHeightController--Box">
                <div className="LineHeightController--Style">
                    <Dropdown
                        list={PredefinedLineHeights}
                        value={lineHeight}
                        onSelect={(size) => handleSelectLineHeight(size)}
                        label="Line Height in px"
                    />
                </div>
            </div>
        </div>
    );
});

export default LineHeightController;
