import React, { useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useMutation } from 'react-query';
import { DownloadIcon, SaveIcon } from '../../assets/icons';
import { Typography } from '../index';
import './index.scss';
import { Post } from '../../services/HttpService';
import { SAVE_TEMPLATE } from '../../api/Endpoints';
import { UseLocalStorage, UseSessionStorage } from '../../utils';  
import { schoolIdTokenKey } from '../../tokens';
import moment from 'moment/moment';
import { useNavigate } from 'react-router';
import NameController from '../../components/controller/NameController/NameController'; // Import the NameController component

const SaveDocument = React.forwardRef((props, ref) => {
    const {
        componentRef,
        rndStyle,
        setRndStyle,
        elements,
        DocumentRef,
        documentSize,
        templateTypeId,
    } = props;

    console.log('-============> elements', elements);

    const schoolId =UseSessionStorage('get', schoolIdTokenKey);


    const [saved, setSaved] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [certificateName, setCertificateName] = useState(''); // State variable for certificate name
    const navigate = useNavigate();

    const handleSuccessSavedDocument = () => {
        console.log('success doc saved');
        setSaved(true);
        setTimeout(() => {
            setSaved(false);
        }, 3000);
    };

    const { mutate: saveDocument, data: savedDocument } = useMutation(
        (data) => Post(SAVE_TEMPLATE, data),
        {
            onSuccess: handleSuccessSavedDocument,
        }
    );

    const handlePrint = useReactToPrint({
        content: () => DocumentRef.current,
        pageStyle: `@media print {
        @page {
            size: ${documentSize?.format};
            margin:0;
        }
        }`,
    });

    // Function to receive the certificate name from NameController component
    const handleCertificateNameChange = (name) => {
        setCertificateName(name);
    };

    const filterElementsById = (element, index) => {
        return elements.findIndex((obj) => obj.id === element.id) === index;
    };

    const filterByVariableType = (element) =>
        element?.variable_type === 'default' || element?.variable_type === 'custom';

    const getCurrentDateTime = () => {
        const currentTimestamp = Date.now();
        const formattedDateTime = moment(currentTimestamp).format('YYYY-MM-DD HH:mm:ss');
        return formattedDateTime;
    };

    const createFormData = (variables) => {
        const formData = new FormData();
        let currentDateTime = getCurrentDateTime();
        let name = `${certificateName}_${currentDateTime}`;

        if (!name) {
            if (templateTypeId === 1) {
                name = `Birth_certificate_${schoolId}_${currentDateTime}`;
            } else if (templateTypeId === 2) {
                name = `Character Certificate_${schoolId}_${currentDateTime}`;
            }
        }

        // Console log the name of the certificate
        console.log('Certificate Name:', name);

        if (componentRef.current.children[0].innerHTML) {
            formData.append('template_id', '');

            formData.append('template_name', name);
            formData.append('template_type_id', templateTypeId ?? 1);
            formData.append('html_variables', JSON.stringify(variables));
            formData.append('html_content', componentRef.current.innerHTML);
            formData.append('school_id', schoolId);
            for (let [key, value] of formData.entries()) {
                console.log(`FORM DATA : ${key}: ${value}`);
            }
            return formData;
        } else {
            return null;
        }
    };

    const handleSaveDocument = () => {
        console.log('name', certificateName);
        console.log('Elements', elements);
        const removedDuplicateVariable = elements.filter(filterElementsById);
        console.log('Remove Duplicates', removedDuplicateVariable);
        console.log('Filter Variable Type', filterByVariableType);

        const htmlVariables = removedDuplicateVariable.filter(filterByVariableType);
        console.log('HTML Variables', htmlVariables);

        const formattedHtmlVariables = htmlVariables.map(
            ({ label, type, element, style, ...rest }) => rest
        );

        const formData = createFormData(formattedHtmlVariables);
        if (formData) {
            saveDocument(formData);
        } else {
            setErrorMessage('Nothing To Save');
            setTimeout(() => {
                setErrorMessage(null);
            }, 4000);
        }
    };

    return (
        <>
            <div className="SaveDocument">
                <div
                    onClick={() => {
                        if (ref?.style?.borderColor === 'blue') {
                            ref.style.border = 'none';
                        }
                        return handlePrint();
                    }}
                    className="SaveDocument--Download SaveDocument--Divider"
                >
                    <DownloadIcon />
                    <Typography>DOWNLOAD & PREVIEW</Typography>
                </div>
                <div className="SaveDocument--Download" onClick={handleSaveDocument}>
                    <SaveIcon />
                    <Typography>{saved ? 'Saved' : 'Save'}</Typography>
                </div>
            </div>
            <p
                style={{
                    color: 'red',
                    textAlign: 'center',
                    fontWeight: 700,
                    fontSize: '1.2rem',
                    marginTop: '1rem',
                }}
            >
                {errorMessage}
            </p>
            {/* Pass the handleCertificateNameChange function as a prop to the NameController component */}
            <NameController onCertificateNameChange={handleCertificateNameChange} />
        </>
    );
});

export default SaveDocument;
