import React, { useEffect, useState } from 'react';
import { ColorPicker, Typography } from '../../index';
import './ElementBorderController.scss';

const ElementBorderController = React.forwardRef((props, ref) => {
    const [borderWidth, setBorderWidth] = useState(2);
    const [borderStyle, setBorderStyle] = useState('');
    const [borderColor, setBorderColor] = useState('#000');

    const element = ref;

    useEffect(() => {
        if (ref) {
            setBorderStyle(ref?.style?.borderStyle);
        }
    }, [ref]);

    const handleBorderStyleChange = (e) => {
        setBorderStyle(e.target.value);
        if (ref.classList[0] == 'Line') {
            element.style.borderTop = `${borderWidth}px ${e.target.value} ${borderColor}`;
        } else {
            element.style.border = `${borderWidth}px ${e.target.value} ${borderColor}`;
        }
    };

    const handleBorderWidth = (e) => {
        const borderSize = e.target.value;
        if (ref.classList[0] == 'Line') {
            setBorderWidth(borderSize);
            element.style.borderTop = `${borderSize}px ${borderStyle} ${borderColor}`;
        } else {
            setBorderWidth(borderSize);
            element.style.border = `${borderSize}px ${borderStyle} ${borderColor}`;
        }
    };

    const handleBorderColor = (color) => {
        if (ref.classList[0] == 'Line') {
            setBorderColor(color);
            element.style.borderTop = `${borderWidth}px ${borderStyle} ${color}`;
        } else {
            setBorderColor(color);
            element.style.border = `${borderWidth}px ${borderStyle} ${color}`;
        }
    };

    return (
        <>
            <div className="ElementBorderController">
                <Typography className="ElementBorderController--title">
                    Element border line
                </Typography>
                <div className="ElementBorderController--Box">
                    <div className="ElementBorderController--Size">
                        <input
                            type="number"
                            value={borderWidth}
                            onChange={handleBorderWidth}
                        />
                    </div>
                    <div className="ElementBorderController--Style">
                        <select value={borderStyle} onChange={handleBorderStyleChange}>
                            <option value="">None</option>
                            <option value="dashed">Dashed</option>
                            <option value="dotted">Dotted</option>
                            <option value="solid">Solid</option>
                        </select>
                    </div>
                </div>
                <div className="ElementBorderController--BorderColor">
                    <ColorPicker onColorChange={handleBorderColor} />
                </div>
            </div>
        </>
    );
});

export default ElementBorderController;
