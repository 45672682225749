import React, { useEffect, useState } from 'react';
import './Editor.scss';
import { EditorElement } from '../index';

const Editor = React.forwardRef((props, ref) => {
    const {
        elements,
        setElementRef,
        rndStyle,
        setRndStyle,
        setElements,
        DocumentRef,
        documentSize,
    } = props;

    const { width, height } = documentSize;

    const pageSize = {
        width,
        height,
    };

    useEffect(() => {
        if (DocumentRef) {
            DocumentRef.current.innerHTML = '';
        }
    }, []);

    return (
        <div className="Editor" ref={DocumentRef} style={pageSize}>
            {elements?.map((element, index) => (
                <EditorElement
                    key={index}
                    element={element}
                    ref={ref}
                    rndStyle={rndStyle}
                    setElementRef={setElementRef}
                    setRndStyle={setRndStyle}
                />
            ))}
        </div>
    );
});

export default Editor;
