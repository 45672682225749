import React from "react";

const Pencil = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 12.828 12.825"
        >
            <g id="pencil" transform="translate(0.153 0.15)">
                <g id="Group_127" data-name="Group 127">
                    <path
                        id="Path_128"
                        data-name="Path 128"
                        d="M12.192,2.035,10.484.325a1.164,1.164,0,0,0-1.611,0L1.221,8.053a.286.286,0,0,0-.072.122L.011,12.164a.285.285,0,0,0,.352.352l3.985-1.14a.286.286,0,0,0,.122-.072l7.721-7.659a1.14,1.14,0,0,0,0-1.612ZM7.632,2.4,8.68,3.445,3.209,8.916,2.816,8.13a.285.285,0,0,0-.255-.157H2.106ZM.7,11.828l.371-1.3L2,11.457Zm3.286-.939-1.362.389L1.249,9.9l.389-1.362h.748l.491.981A.285.285,0,0,0,3,9.651l.981.491v.748Zm.569-.468V9.965A.285.285,0,0,0,4.4,9.711l-.786-.393L9.082,3.847,10.13,4.9ZM11.79,3.25,10.535,4.494l-2.5-2.5L9.276.738a.583.583,0,0,1,.805,0l1.708,1.708a.569.569,0,0,1,0,.8Z"
                        transform="translate(0 -0.002)"
                        fill="#1e89fd"
                        stroke="#1e89fd"
                        strokeWidth="0.3"
                    />
                </g>
            </g>
        </svg>
    );
};

export default Pencil;
