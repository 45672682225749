import React from "react";

const UserProfile = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="24pt"
      height="24pt"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="#3F3F40"
        stroke="none"
      >
        <path
          d="M2415 5113 c-534 -33 -1032 -223 -1444 -551 -919 -730 -1223 -1988
-739 -3059 135 -298 363 -612 601 -826 l78 -70 27 21 c38 30 165 103 547 310
182 99 349 196 372 216 23 20 54 62 70 94 27 55 28 62 31 248 l4 191 -46 63
c-97 131 -197 334 -242 489 -20 68 -33 94 -63 126 -75 78 -76 81 -76 355 0
261 2 276 55 335 17 18 19 50 23 355 4 376 7 393 78 539 121 246 391 381 799
398 466 19 805 -125 940 -400 71 -145 73 -160 71 -532 -2 -328 -2 -331 19
-350 12 -11 32 -38 44 -60 20 -38 21 -54 21 -285 0 -233 -1 -247 -22 -286 -25
-47 -82 -101 -128 -120 -26 -10 -34 -23 -49 -71 -29 -97 -162 -352 -236 -455
l-70 -97 0 -189 c0 -221 8 -255 78 -333 42 -47 79 -67 486 -271 243 -121 467
-238 499 -259 l58 -40 66 58 c173 152 366 388 494 605 329 554 434 1223 293
1858 -191 859 -820 1565 -1653 1854 -311 108 -671 159 -986 139z"
        />
      </g>
    </svg>
  );
};

export default UserProfile;
