import React, { useEffect } from "react";
import { AddImage } from "../../assets/icons";
import { useImageReader } from "../../hooks";
import Typography from "../common/Typography/Typography";
import "./ImageUpload.scss";
const ImageUpload = (props) => {
    const { label, placeholder, onUpload } = props;

    const [imageUrl, readImage] = useImageReader();

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        readImage(file);
    };

    useEffect(() => {
        if (imageUrl) {
            onUpload(imageUrl);
        }
    }, [imageUrl]);

    return (
        <div className="ImageUpload">
            <div className="ImageUpload--Label">
                <Typography>{label}</Typography>
            </div>
            <label className="ImageUpload--ActiveArea" htmlFor="image-upload">
                <div className="ImageUpload--Icon">
                    <AddImage />
                </div>
                <div className="ImageUpload--label">
                    <Typography className="LabelText" color="#AAB0CF">
                        {placeholder}
                    </Typography>
                </div>
            </label>
            <input
                type="file"
                accept="image/*"
                id="image-upload"
                hidden
                onChange={handleImageUpload}
            />
        </div>
    );
};

export default ImageUpload;
