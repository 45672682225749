import React from "react";
import "./IdCardFields.scss";
const IdCardFields = () => {
    return (
        <div className="IdCardFields">
            <h1>IdCardFields</h1>
        </div>
    );
};

export default IdCardFields;
