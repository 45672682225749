import React, { useState } from 'react';
import { CompanyLogo } from '../../../assets/icons';
import './SideMenuCerti.scss';
import MenuIcon from '@mui/icons-material/Menu';
import CancelIcon from '@mui/icons-material/Cancel';
import { Button, IconButton, SwipeableDrawer } from '@mui/material';
import { Link } from 'react-router-dom';
const SideMenuCerti = (props) => {
    const { children } = props;
    const [sidebarToogle, setSidebarToogle] = useState(false);
    const linkStyle = {
        textDecoration: 'none',
        color: 'white',
        backgroundColor: '#DFE4F3',
        padding: '10px 25px',
        color: '#004b85',
        borderRadius: '4px',
        margin: '4px',
        fontWeight: '800',
        fontSize: '16px',
    };

    return (
        <>
            <div className="SideMenu">
                <div className="SideMenu--Header">
                    <div className="SideMenu--Logo">
                        <CompanyLogo className="mitras" />
                    </div>
                </div>
                <div className="SideMenu--Body">{children}</div>
                <div className="SideMenu--footer">
                    <Link to="/list-document" style={linkStyle}>
                        List Document
                    </Link>
                    <Link to="/issue-certificate" style={linkStyle}>
                        Issue Document
                    </Link>
                </div>
            </div>
            <div className="MobileSideMenuParent">
                <Button
                    onClick={() => {
                        setSidebarToogle((prev) => !prev);
                    }}
                >
                    <MenuIcon sx={{ color: '004b85', fontSize: '5rem' }} />
                </Button>
                <SwipeableDrawer open={sidebarToogle} anchor="left">
                    <div className="MobileSideMenu">
                        <div className="MobileSideMenu--Header">
                            <div className="MobileSideMenu--Logo">
                                <CompanyLogo className="mitras" />

                                <IconButton onClick={() => setSidebarToogle(false)}>
                                    <CancelIcon
                                        sx={{ color: '#fff', fontSize: '3rem' }}
                                    />
                                </IconButton>
                            </div>
                        </div>
                        <div className="MobileSideMenu--Body">{children}</div>

                        <div className="SideMenu--footer">
                            <Link to="/list-document" style={linkStyle}>
                                List Document
                            </Link>
                            <Link to="/issue-certificate" style={linkStyle}>
                                Issue Document
                            </Link>
                        </div>
                    </div>
                </SwipeableDrawer>
            </div>
        </>
    );
};

export default SideMenuCerti;
