import { useState } from "react";

const useImageReader = () => {
    const [imageUrl, setImageUrl] = useState("");

    const handleImageLoad = (event) => {
        setImageUrl(event.target.result);
    };

    const readImage = (file) => {
        const reader = new FileReader();
        reader.onload = handleImageLoad;
        reader.readAsDataURL(file);
    };

    return [imageUrl, readImage];
};

export default useImageReader;
