import React from "react";
import "./FontController.scss";
import {
    FontColor,
    FontStyle,
    FontAlignment,
    FontPicker,
} from "../../FontConfiguration";
import { Typography } from "../../common";

const FontController = React.forwardRef((props, ref) => {
    return (
        <div className="FontController">
            <Typography className="FontController--Title">
                + ADD TEXT BOX
            </Typography>
            <FontPicker ref={ref} />
            <FontStyle ref={ref} />
            <FontColor ref={ref} />
            <FontAlignment ref={ref} />
        </div>
    );
});

export default FontController;
