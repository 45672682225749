import React from "react";
import "./Divider.scss";
const Divider = (props) => {
    const { width, height, margin, color } = props;

    return (
        <div
            className="Divider"
            style={{
                width,
                borderColor: color,
                borderWidth: height,
                margin,
            }}
            {...props}
        ></div>
    );
};

export default Divider;
