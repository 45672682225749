import React from "react";

const AlignCenter = () => {
  return (
    <div>
      <svg
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5 6H19M7 10H17M5 14H19M7 18H17"
          stroke="#AAB0CF"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
};

export default AlignCenter;
