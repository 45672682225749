import React, { useState } from 'react';
import './Dropdown.scss';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useEffect } from 'react';
const Dropdown = (props) => {
    const {
        list,
        onSelect,
        value,
        isLabel,
        styleDropDownMenu,
        suffix,
        label,
        className,
    } = props;
    const [isOpen, setIsOpen] = useState(false);

    const displayLabel = isLabel === false ? false : true;

    const defaultText = label ? label : list[0];

    const [selectedItem, setSelectedItem] = useState(defaultText);

    const customClassName = `Dropdown ${className}`;

    const IconSize = {
        fontSize: '25px',
    };

    const handleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const styleList = (item) => {
        switch (true) {
            case selectedItem === item:
                return {
                    color: '#004b85',
                    background: '#dfe4f3',
                    fontWeight: 700,
                };
            default:
                return {
                    background: '#fff',
                };
        }
    };

    const handleDropDownClose = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        if (value) {
            if (typeof value === 'string') {
                setSelectedItem(value?.replace(/['"]+/g, ''));
            } else {
                setSelectedItem(value);
            }
        } else {
            if (defaultText) {
                setSelectedItem(defaultText);
            }
        }
        setTimeout(() => handleDropDownClose(), 500);
    }, [value, defaultText]);

    return (
        <React.Fragment>
            <div className={customClassName}>
                <label className="Dropdown--SelectedItem" onClick={handleDropdown}>
                    {displayLabel && (
                        <div className="Dropdown--Value">
                            {selectedItem}
                            {suffix}
                        </div>
                    )}
                    <div className="Dropdown--Icon">
                        {isOpen ? (
                            <ExpandLessIcon sx={IconSize} />
                        ) : (
                            <ExpandMoreIcon sx={IconSize} />
                        )}
                    </div>
                </label>
                {isOpen && (
                    <div className="Dropdown--Menu" style={styleDropDownMenu}>
                        {list?.map((item, index) => (
                            <div
                                className="Dropdown--Menu--Item"
                                key={index}
                                onClick={() => {
                                    onSelect(item);
                                }}
                                style={styleList(item)}
                            >
                                <p>
                                    {item}
                                    {suffix}
                                </p>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </React.Fragment>
    );
};

export default Dropdown;
