import React, { useEffect, useState } from 'react';
import { DownArrow, UserProfile } from '../../../assets/icons';
import Typography from '../Typography/Typography';
import './NavbarCerti.scss';

const NavbarCerti = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [title, setTitle] = useState('');

    useEffect(() => {
        if (window.location.pathname == '/issue-certificate') {
            setTitle('Issue Certificate');
        } else if (window.location.pathname == '/list-document') {
            setTitle('Certificate Admin');
        } else {
            setTitle('Issue Certificate');
        }
    }, [window.location.pathname]);

    return (
        <div className="Navbar">
            <div className="Navbar--Left">
                <p>{title}</p>
            </div>
            <div className="Navbar--Right">
                <Typography color="#2B3247" size="1.4rem">
                    Welcome to SchoolMitra
                </Typography>
                <div className="Navbar--Profile">
                    <UserProfile />
                    <div
                        name="ee"
                        className="Navbar--Profile--Arrow"
                        onClick={(e) => {
                            e.stopPropagation();
                            setIsOpen((prev) => !prev);
                        }}
                    >
                        <DownArrow />
                    </div>

                    {isOpen && (
                        <div className="Navbar--Profile--DropDown">
                            <Typography size="1.5rem">Logout</Typography>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default NavbarCerti;
