import React, { useState, useEffect } from 'react';
import { Typography } from '../../common';
import { LineThickness } from '../../../utils';
import './LineThicknessController.scss';

const LineThicknessController = React.forwardRef((props, ref) => {
    const { lineRef } = props;
    const Line = lineRef?.current;

    const element = ref?.current?.firstChild;
    const [thickness, setThickness] = useState();

    console.log('lineRef ===========>', Line, Line?.firstChild?.style?.height);

    console.log('Ref ===============>', ref?.style?.height, element);

    const handleSelectThickness = (e) => {
        const LineThickness = e.target.value;
        setThickness(LineThickness);
        ref.style.borderWidth = LineThickness;
    };

    useEffect(() => {
        if (Line && ref) {
            setThickness(ref?.style?.height);
        }
    }, [Line, ref]);

    return (
        <div className="LineThicknessController">
            <Typography className="LineThicknessController--Title" color="#004B85">
                Line Thickness
            </Typography>

            <div className="LineThicknessController--Box">
                <div className="LineThicknessController--Style">
                    <select value={thickness} onChange={handleSelectThickness}>
                        <option>Line Thickness</option>
                        {LineThickness?.map((size) => (
                            <option value={`${size}px`} key={size}>
                                {size}px
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </div>
    );
});

export default LineThicknessController;
