import React from "react";
import "./Button.scss";
const Button = (props) => {
    const { variant, children, disabled, onClick } = props;

    const CustomVariant = variant ? `Button--${variant}` : "Button--contained";

    return (
        <button
            className={`Button ${CustomVariant}`}
            disabled={disabled}
            onClick={onClick}
            {...props}
        >
            {children}
        </button>
    );
};

export default Button;
