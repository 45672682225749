const UseSessionStorage = (operation, key, value) => {
    switch (operation) {
        case 'add':
            return sessionStorage.setItem(key, value);
        case 'remove':
            return sessionStorage.removeItem(key);
        case 'get':
            
            return sessionStorage.getItem(key);
        default:
            break;
    }
};

export default UseSessionStorage;
