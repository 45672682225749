const colors = [
    "#FF6900",
    "#FCB900",
    "#7BDCB5",
    "#00D084",
    "#8ED1FC",
    "#0693E3",
    "#ABB8C3",
    "#EB144C",
    "#F78DA7",
    "#9900EF",
    "#000000",
    "#FFFFFF",
    "#F7F5F5",
    "#F90505",
    "#F9F903",
];

const ColorPalette = () => colors;
export default ColorPalette;
