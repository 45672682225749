import React, { useEffect, useState } from "react";
import { Padding } from "../../../assets/icons";
import { getNumberFromString } from "../../../utils";
import { Typography } from "../../index";
import "./PaddingController.scss";

const PaddingController = React.forwardRef((props, ref) => {
    const [left, setLeft] = useState(0);
    const [right, setRight] = useState(0);
    const [top, setTop] = useState(0);
    const [bottom, setBottom] = useState(0);

    const handleTopPaddingChange = (e) => {
        ref.style.paddingTop = `${e.target.value}px`;
        setTop(e.target.value);
    };

    const handleBottomPaddingChange = (e) => {
        ref.style.paddingBottom = `${e.target.value}px`;
        setBottom(e.target.value);
    };

    const handleLeftPaddingChange = (e) => {
        ref.style.paddingLeft = `${e.target.value}px`;
        setLeft(e.target.value);
    };

    const handleRightPaddingChange = (e) => {
        ref.style.paddingRight = `${e.target.value}px`;
        setRight(e.target.value);
    };

    useEffect(() => {
        if (ref) {
            setLeft(getNumberFromString(ref.style.paddingLeft));
            setRight(getNumberFromString(ref.style.paddingRight));
            setTop(getNumberFromString(ref.style.paddingTop));
            setBottom(getNumberFromString(ref.style.paddingBottom));
        }
    }, [ref]);

    return (
        <div className="PaddingController">
            <Typography className="PaddingController--Title">
                spacing
            </Typography>
            <div className="PaddingController--Box">
                <div className="PaddingController--Input PaddingController--Top">
                    <input
                        value={top}
                        type="number"
                        onChange={handleTopPaddingChange}
                    />
                </div>
                <div className="PaddingController--Input PaddingController--Bottom">
                    <input
                        value={bottom}
                        type="number"
                        onChange={handleBottomPaddingChange}
                    />
                </div>
                <div className="PaddingController--Input PaddingController--Left ">
                    <input
                        value={left}
                        type="number"
                        onChange={handleLeftPaddingChange}
                    />
                </div>
                <div className="PaddingController--Input PaddingController--Right">
                    <input
                        value={right}
                        type="number"
                        onChange={handleRightPaddingChange}
                    />
                </div>
                <Padding />
            </div>
        </div>
    );
});

export default PaddingController;
