import React, { useState, useEffect } from 'react';
import { Dropdown, Typography } from '../../index';
import './PageSizeController.scss';

const pages = ['A3', 'A4', 'A5'];

const PageSizeController = React.forwardRef((props, ref) => {
    const { updateDocumentSize, documentSize } = props;
    const [pageSize, setPageSize] = useState(documentSize?.format);

    const updateDocument = (pageFormateType) => {
        if (pageFormateType === 'A4') {
            return { format: 'A4', width: '21cm', height: '29.7cm', zoom: '100%' };
        } else if (pageFormateType === 'A3') {
            return {
                format: 'A3',
                width: '29.6cm',
                height: '41.9cm',
                zoom: '70%',
            };
        } else {
            return {
                format: 'A5',
                width: '14.80cm',
                height: '20.95cm',
                zoom: '135%',
            };
        }
    };

    useEffect(() => {
        if (pageSize) {
            const documentFormate = updateDocument(pageSize);
            updateDocumentSize(documentFormate);
            props.DocumentRef.current.innerHTML = '';
        }
    }, [pageSize]);

    return (
        <div className="PageSizeController">
            <Typography className="PageSizeController--Title">PAGE SIZE</Typography>
            <div className="PageSizeController--Select">
                <Dropdown
                    list={pages}
                    value={pageSize}
                    onSelect={(size) => setPageSize(size)}
                    className="PageSizeController--PageSize"
                />
            </div>
        </div>
    );
});

export default PageSizeController;
