//this file is used to render the elements in the editor

import React, { useEffect, useState } from 'react';
import { Rnd } from 'react-rnd';
import './index.scss';

const EditorElement = React.forwardRef((props, ref) => {
    const { element, setElementRef, rndStyle, setRndStyle } = props;
    const [userInput, setUserInput] = useState('');
    const [enableResize, setEnableResize] = useState(false);
    const [disableDrag, setDisableDrag] = useState(true);
    const [childElement, setChildElement] = useState(null);

    const handleDragStart = () => {
        if (ref.classList[0] === 'Line') {
            ref.classList.remove('lineSelected');
        }
        setElementRef(childElement);
    };

    const handleResizeStart = () => {
        setElementRef(childElement);
        setDisableDrag(false);
    };

    const handleResizeStop = () => {
        setElementRef(childElement);
    };

    const handleClick = (element) => {
        setElementRef(element.target);
        setChildElement(element.target);
    };

    let value = userInput;

    const onChange = (e) => {
        setUserInput(e.target.value);
    };

    const defaultElementPositionAtXaxis = (elementType) => {
        return window.innerWidth / 50; // Center horizontally
    };

    const defaultElementPositionAtYaxis = (elementType) => {
        return window.innerHeight / 50; // Center vertically
    };

    const setDefaultWidthOfElement = (elementType) => {
        return Math.min(window.innerWidth - 50, 50); // Limit width to avoid overflow
    };

    const setDefaultHeightOfElement = (elementType) => {
        return Math.min(window.innerHeight - 50, 50); // Limit height to avoid overflow
    };

    const isUserSelectEnabled = element.type === 'textarea' ? false : true;

    const defaultRNDConfig = () => {
        const configuredRNDValues = {
            x: defaultElementPositionAtXaxis(element?.type),
            y: defaultElementPositionAtYaxis(element?.type),
            width: setDefaultWidthOfElement(element?.type),
            height: setDefaultHeightOfElement(element?.type),
        };

        return configuredRNDValues;
    };

    const isElementResizable = () => {
        if (element.type === 'line') {
            return { top: false, bottom: false, right: true, left: true };
        }

        return element?.isElementResizable && enableResize;
    };

    const enableElementResizingAndDragging = () => {
        setEnableResize(true);
        setDisableDrag(false);
    };

    useEffect(() => {
        if (ref) {
            if (ref.classList[0] == 'Line') {
                console.log('Line Selected', ref);
                ref.classList.add('lineSelected');
            }

            if (ref.style.border) {
                enableElementResizingAndDragging();
            } else {
                enableElementResizingAndDragging();
            }

            return () => {
                if (ref.style.borderColor === 'blue') ref.style.border = '';
            };
        }
    }, [ref]);

    return (
        <Rnd
            default={defaultRNDConfig()}
            bounds="parent"
            key={element?.index}
            enableResizing={isElementResizable()}
            disableDragging={disableDrag}
            onDragStart={handleDragStart}
            onResizeStart={handleResizeStart}
            className="EditorElement"
            onResizeStop={handleResizeStop}
            style={rndStyle}
            enableUserSelectHack={isUserSelectEnabled}
        >
            {React.cloneElement(React.Children.only(element?.element), {
                value,
                onChange,
                onClick: (e) => handleClick(e),
                style: {
                    ...element.style,
                },
            })}
        </Rnd>
    );
});

export default EditorElement;
