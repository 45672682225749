import React, { useState, useEffect } from "react";
import Slider from "@mui/material/Slider";
import "./ImageBlurController.scss";
import { Box, Typography } from "../../common";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
const ImageBlurController = React.forwardRef((props, ref) => {
    const { required } = props;
    const element = ref;

    const IconSize = {
        fontSize: "25px",
    };
    const [opacity, setOpacity] = useState(1);
    const [isSliderOpen, setIsSliderOpen] = useState(false);
    const handleChange = (e) => {
        if (element) {
            const opacityValue = e.target.value / 10;
            element.style.opacity = opacityValue;
            setOpacity(opacityValue);
        }
    };

    const handleSliderOpen = () => {
        if (required) {
            if (element) {
                setIsSliderOpen(!isSliderOpen);
            } else {
                setIsSliderOpen(false);
            }
        } else {
            setIsSliderOpen(!isSliderOpen);
        }
    };

    useEffect(() => {
        if (element) {
            console.log("element opacity", element.style.opacity);
            const defaultOpacity = element.style.opacity
                ? element.style.opacity
                : 1;
            setOpacity(defaultOpacity);
        }
    }, [element]);

    return (
        <div className="ImageBlurController">
            <Typography className="ImageBlurController--Title">
                Add Opacity
            </Typography>
            <Box>
                <div className="ImageBlurController--LabelWrapper">
                    <label htmlFor="opacity">Opacity:</label>
                    <div className="ImageBlurController--OpacityValue">
                        <div className="ImageBlurController--Value">
                            {opacity * 100}%
                        </div>
                        <div
                            className="ImageBlurController--ControlSlider"
                            onClick={handleSliderOpen}
                        >
                            <KeyboardArrowRightIcon sx={IconSize} />
                        </div>
                    </div>
                </div>
            </Box>
            {isSliderOpen && (
                <div className="ImageBlurController--Slider">
                    <Slider
                        defaultValue={10}
                        min={0}
                        max={10}
                        value={opacity * 10}
                        onChange={handleChange}
                        valueLabelDisplay={"off"}
                        id="opacity"
                    />
                </div>
            )}
        </div>
    );
});

export default ImageBlurController;
