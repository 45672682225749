import React, { useEffect, useState } from "react";
import { InchToCm } from "../../../utils";
import { Typography } from "../../common";
import "./SpaceInDocController.scss";
const sizeInInches = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const SpaceInDocController = React.forwardRef((props, ref) => {
    const { spaceInDoc } = props;

    const space = spaceInDoc.current;

    const [fillSpaceInDoc, setFillSpaceInDoc] = useState();

    const handleSelectSize = (e) => {
        const spaceSize = e.target.value;
        setFillSpaceInDoc(spaceSize);
        space.style.height = spaceSize;
    };

    useEffect(() => {
        if (space) {
            setFillSpaceInDoc(space.style.height);
        }
    }, [space]);
    return (
        <>
            <div className="SpaceInDocController">
                <Typography className="SpaceInDocController--title">
                    Add Space In Doc
                </Typography>
                <div className="SpaceInDocController--Box">
                    <div className="SpaceInDocController--Style">
                        <select
                            value={fillSpaceInDoc}
                            onChange={handleSelectSize}
                        >
                            <option>Add Space (Inch)</option>
                            {sizeInInches.map((inch) => (
                                <option value={InchToCm(inch)} key={inch}>
                                    {inch} inch
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            </div>
        </>
    );
});

export default SpaceInDocController;
