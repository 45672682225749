const SaveIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.827"
      height="17.833"
      viewBox="0 0 22.827 17.833"
    >
      <g
        id="Group_46601"
        data-name="Group 46601"
        transform="translate(2214.495 37.378)"
      >
        <path
          id="Path_44665"
          data-name="Path 44665"
          d="M-2196.12,66.2h8.109a1.186,1.186,0,0,1,.207.005.3.3,0,0,1,.233.454q-.771,1.727-1.548,3.449c-.028.063-.043.157-.128.146-.115-.015-.072-.124-.074-.2-.006-.18-.007-.361,0-.541.008-.218-.1-.323-.294-.323q-1.788-.005-3.575,0c-.213,0-.292.131-.29.353.006,1.083,0,2.166.007,3.249,0,.2-.042.283-.24.316a1.082,1.082,0,0,0-.741,1.635c.414.754.832,1.506,1.246,2.261.157.287.145.306-.161.306h-12.305l-3.057,0a2.178,2.178,0,0,1-.233,0,.4.4,0,0,1-.331-.613q.968-2.165,1.94-4.328c.861-1.92,1.729-3.836,2.578-5.761a.594.594,0,0,1,.629-.419C-2201.474,66.208-2198.8,66.2-2196.12,66.2Z"
          transform="translate(-4.638 -98.278)"
          fill="none"
          stroke="#004b85"
          stroke-width="1"
        />
        <path
          id="Path_44666"
          data-name="Path 44666"
          d="M-2213.976-29.275c0-2.148.008-4.3,0-6.443a1.085,1.085,0,0,1,1.156-1.16c1.891.022,3.783.006,5.674.008a.966.966,0,0,1,1.078.985,1.227,1.227,0,0,0,1.266,1.148q4.114,0,8.227,0c.574,0,.662.086.662.654q0,.745,0,1.489c0,.462-.035.5-.487.5-4,0-8,.006-12,0a1.268,1.268,0,0,0-1.268.813q-2,4.274-4.018,8.539c-.052.11-.063.324-.221.288-.129-.029-.06-.224-.061-.343Q-2213.979-26.039-2213.976-29.275Z"
          transform="translate(0 0)"
          fill="none"
          stroke="#004b85"
          stroke-width="1"
        />
        <path
          id="Path_44667"
          data-name="Path 44667"
          d="M-1905.193,138.9c0,.595.012,1.19-.006,1.784-.006.222.066.275.271.257a5.99,5.99,0,0,1,.625,0c.292.006.409.2.266.447q-1.146,1.986-2.3,3.969c-.153.264-.386.262-.541,0q-1.146-1.971-2.286-3.946c-.156-.27-.046-.462.262-.467.218,0,.437-.014.653,0,.192.015.236-.055.235-.237-.007-1.18,0-2.36-.008-3.54,0-.249.059-.32.313-.314.747.018,1.5.014,2.243,0,.207,0,.281.043.275.264C-1905.2,137.71-1905.193,138.3-1905.193,138.9Z"
          transform="translate(-289.251 -165.095)"
          fill="#f26311"
        />
      </g>
    </svg>
  );
};

export default SaveIcon;
