import React from 'react';
import './Toggle.scss';

const Toggle = () => {
    return (
        <div className="Toggle">
            <label className="Toggle--Switch">
                <input type="checkbox" style={{ display: 'none' }} />
                <span class="Toggle--Slider Toggle--Round"></span>
            </label>
        </div>
    );
};

export default Toggle;
