import { authTokenKey, schoolIdTokenKey } from '../../tokens';
//import { UseSessionStorage } from '../index';

const StoreUrlToken = () => {
    const currentUrl = window.location.href;
    const searchParams = new URLSearchParams(new URL(currentUrl).search);
    const authToken = searchParams.get("auth_token");
    const schoolId = searchParams.get("school_id");
    sessionStorage.setItem("auth_token", authToken);
    sessionStorage.setItem("school_id", schoolId);
    return true;
};

export default StoreUrlToken;
