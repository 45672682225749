import React from "react";
import { Upload } from "../../assets/icons";
import { FileUpload, Checkbox, Typography } from "../common";
import "./DocumentUpload.scss";
const DocumentUpload = (props) => {
    const { title, subtitle, onChange } = props;
    return (
        <div className="DocumentUpload">
            <div className="DocumentUpload--Type">
                <Typography variant="h2" size="1.7rem">
                    {title}
                </Typography>

                <Typography size="1.2rem" pt="0.5rem">
                    {subtitle}
                </Typography>
            </div>
            <div className="DocumentUpload--AttachFile">
                <FileUpload onChange={onChange} />
            </div>
            <div className="DocumentUpload--Actions">
                <Checkbox label={"Yes"} />
                <Checkbox label={"No"} />
                <Upload />
            </div>
        </div>
    );
};

export default DocumentUpload;
