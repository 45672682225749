import React, { useEffect, useState } from "react";
import ColorPicker from "../../ColorPicker/ColorPicker";
import "./FontColor.scss";

const FontColor = React.forwardRef((props, ref) => {
    const [fontColor, setFontColor] = useState("");

    useEffect(() => {
        if (ref) ref.style.color = fontColor;
    }, [fontColor]);

    const handleFontColor = (color) => {
        setFontColor(color);
    };

    return (
        <div className="FontColor">
            <ColorPicker onColorChange={handleFontColor} />
        </div>
    );
});

export default FontColor;
