import React from "react";
import VerticalLine from "../VerticalLine/VerticalLine";

const AddBtn = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="29"
            height="29"
            viewBox="0 0 29 29"
        >
            <g
                id="Group_46453"
                data-name="Group 46453"
                transform="translate(-350 -744)"
            >
                <g id="Group_46447" data-name="Group 46447">
                    <circle
                        id="Ellipse_392"
                        data-name="Ellipse 392"
                        cx="14.5"
                        cy="14.5"
                        r="14.5"
                        transform="translate(350 744)"
                        fill="#89cff0"
                    />
                    <g
                        id="Group_46446"
                        data-name="Group 46446"
                        transform="translate(358 752)"
                    >
                        <line
                            id="Line_221"
                            data-name="Line 221"
                            y2="13.903"
                            transform="translate(6.951)"
                            fill="none"
                            stroke="#fff"
                            strokeWidth="1.5"
                        />
                        <line
                            id="Line_222"
                            data-name="Line 222"
                            x2="13.903"
                            transform="translate(0 6.951)"
                            fill="none"
                            stroke="#fff"
                            strokeWidth="1.5"
                        />

                        <VerticalLine
                            id="Line_223"
                            data-name="Line 223"
                            y2="13.903"
                            transform="translate(6.951)"
                            fill="none"
                            stroke="#fff"
                            strokeWidth="1.5"
                        />
                        <VerticalLine
                            id="Line_224"
                            data-name="Line 224"
                            x2="13.903"
                            transform="translate(0 6.951)"
                            fill="none"
                            stroke="#fff"
                            strokeWidth="1.5"
                        />

                    </g>
                </g>
            </g>
        </svg>
    );
};

export default AddBtn;
