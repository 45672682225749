import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Box, Button, Input, Typography } from '../../common';
import './UserDefinedVariableController.scss';

const UserDefinedVariableController = (props) => {
    const { updateDefaultMediaAssets } = props;
    const [userInput, setUserInput] = useState('');
    const characterLimit = 50;
    const [charCount, setCharCount] = useState(0);

    // const [userDefinedVariables, setUserDefinedVariable] = useState([
    //     {
    //         id: 0,
    //         title: 'Generic Tools',
    //         type: 'generic_tools',
    //         children: [],
    //     },

    //     {
    //         id: uuidv4(),
    //         title: 'User Defined Variable',
    //         children: [
    //             {
    //                 id: uuidv4(),
    //                 label: 'variable 1',
    //                 element: <p>variable1</p>,
    //                 variable_type: 'custom',
    //                 variable_name: 'variable1',
    //                 variable_field_type: 'text box',
    //                 style: { fontSize: '24px' },
    //             },
    //         ],
    //     },
    // ]);

    const handleUserInput = (e) => {
        const input = e.target.value;

        const count = input.length;

        if (count <= characterLimit + 1) {
            setCharCount(count);
            setUserInput(input);
        }
    }

    const handleAddUserDefinedVariable = () => {
        const newUserDefinedVariable = {
            id: uuidv4(),
            label: userInput,
            element: <p>{userInput}</p>,
            variable_type: 'custom',
            variable_name: userInput,
            variable_field_type: 'text box',
            style: { fontSize: '24px' },
        };


        updateDefaultMediaAssets((prevState) => [
            ...prevState.map((asset) => {
                if (asset.type == 'user_defined_variables') {
                    return {
                        ...asset,
                        children: [...asset.children, newUserDefinedVariable],
                    };
                } else {
                    return asset;
                }
            }),
        ]);
        setUserInput('');
    };

    return (
        <React.Fragment>
            <div className="UserDefinedVariableController--Title">
                <Typography>+ Add user defined Variable</Typography>
            </div>

            <Box className="UserDefinedVariableController">
                <div className="UserDefinedVariableController--Box">
                    <Input
                        placeholder="User defined variable"
                        value={userInput}
                        onChange={handleUserInput}
                        className="UserDefinedVariableController--InputField"
                    />

                    <Button
                        onClick={handleAddUserDefinedVariable}
                        disabled={userInput === '' || charCount > characterLimit}
                    >
                        +
                    </Button>
                </div>
                {charCount > characterLimit ? (
                    <p style={{ color: 'red', marginTop: '1rem' }}>
                        Cannot add more than 50 Characters.
                    </p>
                ) : (
                    ''
                )}
            </Box>
        </React.Fragment>
    );
};

export default UserDefinedVariableController;
