import React from 'react'

const AlignLeft = () => {
  return (
    <div>
        <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 6H19M5 10H15M5 14H19M5 18H15" stroke="#AAB0CF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    </div>
  )
}

export default AlignLeft