import React, { useState } from "react";
import "./checkbox.scss";
import checked from "../../../assets/checked.svg";
import unchecked from "../../../assets/unchecked.svg";

const Checkbox = ({ label }) => {
  const [isChecked, setIsChecked] = useState(false);

  return (
    <div className="checkbox">
      <label htmlFor="checkbox">{label}</label>
      <img
        id="checkbox"
        name="checkbox"
        src={isChecked ? checked : unchecked}
        onClick={() => setIsChecked(!isChecked)}
        alt="checkbox"
      />
    </div>
  );
};

export default Checkbox;
