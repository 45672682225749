import React, { useEffect, useState } from "react";
import { FontFamily, getNumberFromString } from "../../../utils";
import "./FontPicker.scss";
import { Box, Dropdown, Input } from "../../common";

const defaultFontSize = [6, 7, 8, 9, 10, 11, 12, 14, 18, 24, 36];

const FontPicker = React.forwardRef((props, ref) => {
    const [fontSize, setFontSize] = useState(0);
    const [fontFamily, setFontFamily] = useState(FontFamily[0]);

    const handleFontSizeSelect = (size) => {
        setFontSize(size);
        ref.style.fontSize = size + "px";
    };

    const handleFontSize = (e) => {
        setFontSize(e.target.value);
        ref.style.fontSize = e.target.value + "px";
    };

    const handleFontFamily = (family) => {
        setFontFamily(family);
        ref.style.fontFamily = family;
    };

    const styleFontFamilyDropdownMenu = {
        width: "200%",
        top: "35px",
        left: "0",
    };

    const styleFontSizeDropdownMenu = {
        top: "35px",
        left: "0",
    };

    useEffect(() => {
        if (ref) {
            if (ref.style.fontSize) {
                setFontSize(getNumberFromString(ref.style.fontSize));
            }

            setFontFamily(ref.style.fontFamily);
        }
    }, [ref]);

    return (
        <Box className="FontPicker">
            <div className="FontPicker--Family">
                <Dropdown
                    list={FontFamily}
                    value={fontFamily}
                    onSelect={(family) => handleFontFamily(family)}
                    styleDropDownMenu={styleFontFamilyDropdownMenu}
                />
            </div>
            <div className="FontPicker--Size">
                <div className="FontPicker--Wrapper">
                    <div className="FontPicker--InputWrapper">
                        <Input
                            value={fontSize}
                            onChange={handleFontSize}
                            type="text"
                        />
                        <div className="FontPicker--Suffix">
                            <p>px</p>
                        </div>
                    </div>
                    <Dropdown
                        list={defaultFontSize}
                        value={fontSize}
                        onSelect={(size) => handleFontSizeSelect(size)}
                        isLabel={false}
                        suffix="px"
                        styleDropDownMenu={styleFontSizeDropdownMenu}
                    />
                </div>
            </div>
        </Box>
    );
});

export default FontPicker;
