import React, { useEffect, useState } from 'react';
import { ColorPicker, Typography } from '../../index';
import './PageBorderController.scss';

const PageBorderController = React.forwardRef((props, ref) => {
    const [borderWidth, setborderWidth] = useState(2);
    const [borderStyle, setBorderStyle] = useState('');
    const [borderColor, setBorderColor] = useState('#000');

    const element = ref?.current;

    useEffect(() => {
        if (ref) {
            setBorderStyle(ref?.style?.borderStyle);
        }
    }, [ref]);

    const handleBorderStyleChange = (e) => {
        setBorderStyle(e.target.value);
        element.style.border = `${borderWidth}px ${e.target.value} ${borderColor}`;
    };

    const handleBorderWidth = (e) => {
        const borderSize = e.target.value;
        setborderWidth(borderSize);
        element.style.border = `${borderSize}px ${borderStyle} ${borderColor}`;
    };

    const handleBorderColor = (color) => {
        setBorderColor(color);
        element.style.border = `${borderWidth}px ${borderStyle} ${color}`;
    };

    return (
        <>
            <div className="BorderController">
                <Typography className="BorderController--title">
                    Page border line
                </Typography>
                <div className="BorderController--Box">
                    <div className="BorderController--Size">
                        <input
                            type="number"
                            value={borderWidth}
                            onChange={handleBorderWidth}
                        />
                    </div>
                    <div className="BorderController--Style">
                        <select value={borderStyle} onChange={handleBorderStyleChange}>
                            <option value="">None</option>
                            <option value="dashed">Dashed</option>
                            <option value="dotted">Dotted</option>
                            <option value="solid">Solid</option>
                        </select>
                    </div>
                </div>
                <div className="ElementBorderController--BorderColor">
                    <ColorPicker onColorChange={handleBorderColor} />
                </div>
            </div>
        </>
    );
});

export default PageBorderController;
