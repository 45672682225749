import React from "react";

const CompanyLogo = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="130.34"
            height="12.919"
            viewBox="0 0 130.34 12.919"
        >
            <g
                id="Group_14290"
                data-name="Group 14290"
                transform="translate(-397.72 -868.646)"
            >
                <path
                    id="Path_31857"
                    data-name="Path 31857"
                    d="M403.124,881.533a7.52,7.52,0,0,1-2.971-.512,4.256,4.256,0,0,1-1.789-1.335,3.106,3.106,0,0,1-.643-1.754.359.359,0,0,1,.116-.26.38.38,0,0,1,.278-.116h2.255a.693.693,0,0,1,.385.09,1,1,0,0,1,.242.233,2.1,2.1,0,0,0,.4.519,1.872,1.872,0,0,0,.689.4,3.115,3.115,0,0,0,1.038.153,3.053,3.053,0,0,0,1.566-.321.986.986,0,0,0,.529-.877.838.838,0,0,0-.286-.663,2.692,2.692,0,0,0-.922-.457,16.207,16.207,0,0,0-1.691-.422,10.379,10.379,0,0,1-2.317-.744,3.542,3.542,0,0,1-1.458-1.2,3.522,3.522,0,0,1,.119-3.8,4.068,4.068,0,0,1,1.728-1.341,6.557,6.557,0,0,1,2.631-.481,6.7,6.7,0,0,1,2.175.323,4.954,4.954,0,0,1,1.584.86,4.055,4.055,0,0,1,.975,1.137,2.594,2.594,0,0,1,.348,1.173.392.392,0,0,1-.1.259.342.342,0,0,1-.277.116h-2.363a.751.751,0,0,1-.34-.072.658.658,0,0,1-.25-.233,1.228,1.228,0,0,0-.563-.689,2.16,2.16,0,0,0-1.19-.3,2.29,2.29,0,0,0-1.226.277.937.937,0,0,0-.439.85.907.907,0,0,0,.241.627,1.935,1.935,0,0,0,.805.466,13.9,13.9,0,0,0,1.548.413,11.653,11.653,0,0,1,2.631.762,3.373,3.373,0,0,1,1.476,1.182,3.59,3.59,0,0,1-.226,3.911,4.448,4.448,0,0,1-1.907,1.35A7.635,7.635,0,0,1,403.124,881.533Z"
                    transform="translate(0 0)"
                    fill="#fff"
                />
                <path
                    id="Path_31858"
                    data-name="Path 31858"
                    d="M510.693,881.6a6.952,6.952,0,0,1-2.9-.557,4.242,4.242,0,0,1-1.887-1.63,5.416,5.416,0,0,1-.741-2.668q-.017-.734-.017-1.575t.019-1.611a5.392,5.392,0,0,1,.753-2.648,4.261,4.261,0,0,1,1.9-1.646,6.9,6.9,0,0,1,2.882-.553,7.7,7.7,0,0,1,2.183.3,5.464,5.464,0,0,1,1.754.86,4.05,4.05,0,0,1,1.172,1.361,3.951,3.951,0,0,1,.438,1.8.3.3,0,0,1-.1.268.4.4,0,0,1-.277.107h-2.416a.568.568,0,0,1-.358-.1.771.771,0,0,1-.215-.367,2.061,2.061,0,0,0-.814-1.3,2.589,2.589,0,0,0-1.387-.35,2.165,2.165,0,0,0-1.558.545,2.589,2.589,0,0,0-.628,1.817q-.054,1.468,0,3.007a2.587,2.587,0,0,0,.625,1.817,2.163,2.163,0,0,0,1.557.547,2.582,2.582,0,0,0,1.4-.357,2.006,2.006,0,0,0,.806-1.288.647.647,0,0,1,.206-.367.61.61,0,0,1,.367-.1h2.416a.4.4,0,0,1,.277.108.3.3,0,0,1,.1.269,3.947,3.947,0,0,1-.44,1.8,4.052,4.052,0,0,1-1.173,1.36,5.476,5.476,0,0,1-1.755.858A7.711,7.711,0,0,1,510.693,881.6Z"
                    transform="translate(-95.237 -0.059)"
                    fill="#fff"
                />
                <path
                    id="Path_31859"
                    data-name="Path 31859"
                    d="M619.918,882.863a.429.429,0,0,1-.447-.448l.007-11.635a.429.429,0,0,1,.448-.447h2.309a.467.467,0,0,1,.331.125.421.421,0,0,1,.134.322l0,4.314,4.4,0,0-4.314a.429.429,0,0,1,.448-.447h2.309a.43.43,0,0,1,.447.448l-.007,11.635a.445.445,0,0,1-.125.313.421.421,0,0,1-.322.134h-2.309a.42.42,0,0,1-.322-.135.444.444,0,0,1-.125-.313l0-4.457-4.4,0,0,4.457a.429.429,0,0,1-.134.313.449.449,0,0,1-.331.134Z"
                    transform="translate(-196.586 -1.496)"
                    fill="#fff"
                />
                <path
                    id="Path_31860"
                    data-name="Path 31860"
                    d="M738.91,881.742a7.047,7.047,0,0,1-2.864-.539,4.166,4.166,0,0,1-1.9-1.621,5.475,5.475,0,0,1-.75-2.712q-.017-.769-.017-1.548t.019-1.548a5.487,5.487,0,0,1,.762-2.711,4.287,4.287,0,0,1,1.916-1.655,7.555,7.555,0,0,1,5.683,0,4.354,4.354,0,0,1,1.914,1.657,5.307,5.307,0,0,1,.75,2.712q.035.77.035,1.548t-.037,1.548a5.3,5.3,0,0,1-.745,2.711,4.223,4.223,0,0,1-1.907,1.619A7.05,7.05,0,0,1,738.91,881.742Zm0-2.577a2.148,2.148,0,0,0,1.549-.572,2.535,2.535,0,0,0,.637-1.825q.036-.787.037-1.485t-.035-1.45a3.251,3.251,0,0,0-.312-1.37,1.781,1.781,0,0,0-.76-.779,2.378,2.378,0,0,0-1.11-.251,2.34,2.34,0,0,0-1.092.25,1.831,1.831,0,0,0-.77.778,3.148,3.148,0,0,0-.323,1.369q-.018.752-.019,1.45t.017,1.486a2.588,2.588,0,0,0,.652,1.826A2.125,2.125,0,0,0,738.911,879.165Z"
                    transform="translate(-297.571 -0.185)"
                    fill="#fff"
                />
                <path
                    id="Path_31861"
                    data-name="Path 31861"
                    d="M851.213,881.812a7.049,7.049,0,0,1-2.864-.539,4.168,4.168,0,0,1-1.9-1.621,5.475,5.475,0,0,1-.75-2.712q-.017-.769-.017-1.548t.019-1.548a5.49,5.49,0,0,1,.762-2.711,4.29,4.29,0,0,1,1.916-1.655,7.553,7.553,0,0,1,5.683,0,4.353,4.353,0,0,1,1.914,1.657,5.307,5.307,0,0,1,.75,2.712q.035.77.035,1.548t-.037,1.548a5.3,5.3,0,0,1-.745,2.711,4.222,4.222,0,0,1-1.907,1.619A7.051,7.051,0,0,1,851.213,881.812Zm0-2.578a2.149,2.149,0,0,0,1.549-.572,2.535,2.535,0,0,0,.637-1.825q.036-.787.037-1.486t-.035-1.45a3.251,3.251,0,0,0-.312-1.37,1.782,1.782,0,0,0-.76-.779,2.377,2.377,0,0,0-1.11-.251,2.336,2.336,0,0,0-1.092.25,1.83,1.83,0,0,0-.77.778,3.145,3.145,0,0,0-.323,1.369q-.019.752-.019,1.45t.017,1.486a2.589,2.589,0,0,0,.652,1.826A2.126,2.126,0,0,0,851.214,879.234Z"
                    transform="translate(-397.13 -0.246)"
                    fill="#fff"
                />
                <path
                    id="Path_31862"
                    data-name="Path 31862"
                    d="M962.031,883.075a.42.42,0,0,1-.322-.134.445.445,0,0,1-.125-.313l.007-11.635a.429.429,0,0,1,.448-.447H964.4a.429.429,0,0,1,.447.448l-.006,9.38,5.424,0a.422.422,0,0,1,.322.134.469.469,0,0,1,.125.331v1.79a.447.447,0,0,1-.125.313.421.421,0,0,1-.322.134Z"
                    transform="translate(-499.876 -1.684)"
                    fill="#fff"
                />
                <path
                    id="Path_31863"
                    data-name="Path 31863"
                    d="M1056.194,883.133a.459.459,0,0,1-.447-.448l.007-11.635a.421.421,0,0,1,.135-.322.445.445,0,0,1,.313-.125h1.933a.589.589,0,0,1,.448.152.9.9,0,0,1,.179.242l3.147,5.64,3.154-5.636a1.84,1.84,0,0,1,.188-.242.559.559,0,0,1,.439-.152h1.915a.469.469,0,0,1,.331.125.423.423,0,0,1,.134.323l-.007,11.635a.43.43,0,0,1-.135.313.45.45,0,0,1-.331.134h-2.13a.45.45,0,0,1-.331-.135.428.428,0,0,1-.134-.313l0-6.712-2.007,3.74a1.067,1.067,0,0,1-.233.286.579.579,0,0,1-.394.125h-.931a.579.579,0,0,1-.394-.126,1.066,1.066,0,0,1-.233-.287l-2.02-3.742,0,6.712a.446.446,0,0,1-.126.313.421.421,0,0,1-.322.134Z"
                    transform="translate(-583.353 -1.735)"
                    fill="#f77b18"
                />
                <path
                    id="Path_31864"
                    data-name="Path 31864"
                    d="M1186.793,883.214a.42.42,0,0,1-.322-.135.442.442,0,0,1-.125-.313l.007-11.635a.429.429,0,0,1,.448-.447h2.4a.469.469,0,0,1,.331.125.423.423,0,0,1,.134.323l-.007,11.635a.429.429,0,0,1-.135.313.451.451,0,0,1-.331.134Z"
                    transform="translate(-699.132 -1.807)"
                    fill="#f77b18"
                />
                <path
                    id="Path_31865"
                    data-name="Path 31865"
                    d="M1233.846,883.244a.419.419,0,0,1-.322-.135.442.442,0,0,1-.125-.313l.006-9.272-3.186,0a.422.422,0,0,1-.322-.135.444.444,0,0,1-.125-.313v-1.915a.429.429,0,0,1,.448-.447l9.63.006a.429.429,0,0,1,.447.448v1.915a.444.444,0,0,1-.125.313.421.421,0,0,1-.322.134l-3.186,0-.006,9.272a.446.446,0,0,1-.125.313.421.421,0,0,1-.323.134Z"
                    transform="translate(-737.63 -1.831)"
                    fill="#f77b18"
                />
                <path
                    id="Path_31866"
                    data-name="Path 31866"
                    d="M1337.265,883.307a.42.42,0,0,1-.322-.135.442.442,0,0,1-.125-.313l.007-11.635a.43.43,0,0,1,.448-.447l4.869,0a5.675,5.675,0,0,1,3.65,1.067,4.1,4.1,0,0,1,.713,5.164,3.812,3.812,0,0,1-1.612,1.324l2.449,4.4a.443.443,0,0,1,.053.2.4.4,0,0,1-.376.376l-2.38,0a.659.659,0,0,1-.5-.17,1.345,1.345,0,0,1-.233-.313l-2.038-3.939h-1.826l0,3.974a.431.431,0,0,1-.135.313.45.45,0,0,1-.331.134Zm2.779-6.961h2.058a1.737,1.737,0,0,0,1.262-.411,1.482,1.482,0,0,0,.421-1.11,1.582,1.582,0,0,0-.4-1.128,1.682,1.682,0,0,0-1.28-.431h-2.058Z"
                    transform="translate(-832.528 -1.889)"
                    fill="#f77b18"
                />
                <path
                    id="Path_31867"
                    data-name="Path 31867"
                    d="M1436.56,883.39a.4.4,0,0,1-.376-.376.674.674,0,0,1,.018-.161l4.214-11.489a.716.716,0,0,1,.224-.349.688.688,0,0,1,.474-.152l2.649,0a.69.69,0,0,1,.474.153.722.722,0,0,1,.223.349l4.181,11.494a.36.36,0,0,1-.081.421.379.379,0,0,1-.278.116h-2.2a.548.548,0,0,1-.4-.135.675.675,0,0,1-.17-.242l-.7-1.826-4.779,0-.681,1.825a.714.714,0,0,1-.161.242.57.57,0,0,1-.43.134Zm4.209-4.777,3.329,0-1.662-4.726Z"
                    transform="translate(-920.618 -1.965)"
                    fill="#f77b18"
                />
            </g>
        </svg>
    );
};
export default CompanyLogo;
