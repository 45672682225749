import React, { useState } from 'react';
import { ChromePicker, TwitterPicker } from 'react-color';
import Typography from '../common/Typography/Typography';
import { ColorPalette } from '../../utils';
import './ColorPicker.scss';
import rgbHex from 'rgb-hex';

const ColorPicker = (props) => {
    const { onColorChange, style, defaultColor } = props;

    const defaultHexColor = defaultColor ? defaultColor : '#000000';

    const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
    const [color, setColor] = useState(defaultHexColor);
    const [isCustomColorPalette, setIsCustomColorPalette] = useState(false);

    const handleColorChange = (color) => {
        // setColor(color.hex);
        setColor('#' + rgbHex(color.rgb.r, color.rgb.g, color.rgb.b, color.rgb.a));
        onColorChange('#' + rgbHex(color.rgb.r, color.rgb.g, color.rgb.b, color.rgb.a));
        if (!isCustomColorPalette) {
            setIsColorPickerOpen(!isColorPickerOpen);
        }
    };

    const handleCustomColor = () => {
        setIsCustomColorPalette(!isCustomColorPalette);
    };
    const handleOpenColorPicker = () => {
        setIsColorPickerOpen(!isColorPickerOpen);
    };

    return (
        <React.Fragment>
            <div className="ColorIndicator">
                <div
                    className="ColorIndicator--SelectedColor"
                    style={{ background: color }}
                    onClick={handleOpenColorPicker}
                ></div>
                <div className="ColorIndicator--ColorCode">
                    <input type="text" value={color} />
                </div>
            </div>
            {isColorPickerOpen && (
                <div className="ColorPicker" style={style} {...props}>
                    <Typography className="ColorPicker--Title" pb="1rem">
                        {isCustomColorPalette ? 'Custom Colour' : 'Standard Colour'}
                    </Typography>
                    {isCustomColorPalette ? (
                        <ChromePicker color={color} onChange={handleColorChange} />
                    ) : (
                        <div>
                            <TwitterPicker
                                colors={ColorPalette()}
                                color={color}
                                onChange={handleColorChange}
                                triangle="hide"
                            />
                            <style jsx>{`
                                .twitter-picker input {
                                    pointer-events: none;
                                    background-color: #f5f5f5;
                                }
                            `}</style>
                        </div>
                    )}
                    <div className="ColorPicker--CustomColorHeader">
                        <Typography
                            className="ColorPicker--CustomColorText"
                            pt="2rem"
                            onClick={handleCustomColor}
                        >
                            {isCustomColorPalette ? 'Switch Standard' : 'Switch Custom'}
                        </Typography>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default ColorPicker;
