import React from "react";
import { AddButton } from "../../../assets/icons";
import "./FileUpload.scss";
const FileUpload = (props) => {
  const { onChange } = props;
  return (
    <div className="FileUpload">
      <input id="file-picker" type="file" hidden onChange={onChange} />
      <label htmlFor="file-picker">
        <AddButton className="FileUpload--Button--Icon" />
      </label>
    </div>
  );
};

export default FileUpload;
