import React from "react";
import { Typography } from "..";
import "./ChooseTemplate.scss";

const ChooseTemplate = (props) => {
    const { name, onClick } = props;
    return (
        <div className="ChooseTemplate" onClick={onClick}>
            <label htmlFor={name}>
                <Typography
                    className="ChooseTemplate--TemplateName"
                    size="2rem"
                    pl="0.5rem"
                >
                    {name}
                </Typography>
            </label>
            <div className="ChooseTemplate--ChooseButton">
                <div className="ChooseTemplate--DragArea">
                    <Typography className="ChooseTemplate--AddIcon" size="5rem">
                        +
                    </Typography>
                </div>
            </div>
        </div>
    );
};

export default ChooseTemplate;
