import React from 'react';
import './TableController.scss';
import { Box, Button, Typography } from '../../common';

const TableController = React.forwardRef((props, ref) => {
    const handleAddRow = () => {
        const closestTable = ref.closest('.EditorElement').querySelector('table');
        const numCols = closestTable.rows[0].cells.length;
        const newRow = closestTable.insertRow();
        for (let i = 0; i < numCols; i++) {
            newRow.insertCell(i);
        }
    };

    const handleAddColumn = () => {
        const closestTable = ref.closest('.EditorElement').querySelector('table');
        const rows = closestTable.rows;
        const closestThead = ref.closest('.EditorElement').querySelector('thead');
        closestThead.style.backgroundColor = '#f2f2f2';
        for (let i = 0; i < rows.length; i++) {
            rows[i].insertCell(-1);
        }
    };

    const handleDeleteRow = () => {
        const closestTable = ref.closest('.EditorElement').querySelector('table');

        const numRows = closestTable.rows.length;
        if (numRows > 1) {
            closestTable.deleteRow(numRows - 1);
        }
    };

    const handleDeleteColumn = () => {
        const closestTable = ref.closest('.EditorElement').querySelector('table');
        const rows = closestTable.rows;
        const numCols = rows[0].cells.length;

        for (let i = 0; i < rows.length; i++) {
            rows[i].deleteCell(numCols - 1);
        }
    };

    const handleDeleteTable = () => {
        return ref.closest('.EditorElement').querySelector('table').remove();
    };

    return (
        <div className="TableController">
            <Typography className="TableController--Title" color="#004B85">
                Add Table
            </Typography>
            <Box className="TableController--Controller">
                <div className="TableController--Add">
                    <Button onClick={handleAddRow}>+</Button>
                </div>
                <div className="TableController--Label">Row</div>
                <div className="TableController--Remove">
                    <Button onClick={handleDeleteRow}>-</Button>
                </div>
            </Box>

            <Box className="TableController--Controller">
                <div className="TableController--Add">
                    <Button onClick={handleAddColumn}>+</Button>
                </div>
                <div className="TableController--Label">Column</div>
                <div className="TableController--Remove">
                    <Button onClick={handleDeleteColumn}>-</Button>
                </div>
            </Box>

            <div className="TableController--DeleteTable">
                <Button onClick={handleDeleteTable}>Delete Table</Button>
            </div>
        </div>
    );
});

export default TableController;
