import React from 'react';
import { Divider, ImageUpload, SaveDocument } from '../index';
import { v4 as uuidv4 } from 'uuid';
import './ToolsCerti.scss';
import {
    BackgroundController,
    DeleteController,
    ElementBorderController,
    FontController as TextController,
    ImageBlurController,
    LineHeightController,
    LineThicknessController,
    PaddingController,
    PageBorderController,
    PageSizeController,
    SpaceInDocController,
    TableController,
    UserDefinedVariableController,
    NameController,
} from '../controller';

const ToolsCerti = React.forwardRef((props, ref) => {
    const {
        componentRef,
        setSelectedElementsList,
        rndStyle,
        setRndStyle,
        tableRef,
        spaceInDoc,
        lineRef,
        textAreaRef,
        elements,
        defaultMediaAssets,
        updateDefaultMediaAssets,
        DocumentRef,
        updateDocumentSize,
        documentSize,
        templateTypeId,
    } = props;

    const onUpload = async (imageSrc, style) => {
        const InsertedImage = {
            type: 'img',
            element: (
                <img 
                    src={imageSrc} 
                    alt="backgroundLogo" 
                    draggable={false}
                    style={style}
                />
            ),
        };
        await setSelectedElementsList((prev) => [...prev, InsertedImage]);
    };

    const imageStyle = {
        maxWidth: '100%',
        height: 'auto',
        resize: 'both',
        overflow: 'auto',
        cursor: 'grab',
    };

    console.log('=================> tools page size default', documentSize);

    return (
        <div className="Tools">
            <SaveDocument
                elements={elements}
                componentRef={componentRef}
                rndStyle={rndStyle}
                setRndStyle={setRndStyle}
                ref={ref}
                templateTypeId={templateTypeId}
                DocumentRef={DocumentRef}
                updateDocumentSize={updateDocumentSize}
                documentSize={documentSize}
            />
            
            <UserDefinedVariableController
                defaultMediaAssets={defaultMediaAssets}
                updateDefaultMediaAssets={updateDefaultMediaAssets}
            />
            <Divider />

            <PageSizeController
                DocumentRef={DocumentRef}
                updateDocumentSize={updateDocumentSize}
                documentSize={documentSize}
            />
            <Divider />

            <DeleteController ref={ref} DocumentRef={DocumentRef} />
            <Divider />

            <TextController ref={ref} />
            <Divider />

            <ImageBlurController ref={ref} required={true} />
            <Divider />

            <BackgroundController ref={ref} />
            <Divider />

            <LineHeightController textAreaRef={textAreaRef} ref={ref} />
            <Divider />

            <SpaceInDocController spaceInDoc={spaceInDoc} />
            <Divider />

            <TableController ref={ref} tableRef={tableRef} />
            <Divider />


            <PaddingController ref={ref} />
            <Divider />

            <ElementBorderController ref={ref} />
            <Divider />

            <LineThicknessController lineRef={lineRef} ref={ref} />
            <Divider />

            <ImageUpload label="Insert Background Image" placeholder="Photo" onUpload={onUpload} style={imageStyle} />
            <Divider />

            <PageBorderController ref={DocumentRef} DocumentRef={DocumentRef} />

        </div>
    );
});

export default ToolsCerti;
