import React from "react";

const Delete = () => {
    return (
        <svg
            id="Group_46027"
            data-name="Group 46027"
            xmlns="http://www.w3.org/2000/svg"
            width="26"
            height="26"
            viewBox="0 0 18.4 19.824"
        >
            <path
                id="Path_36226"
                data-name="Path 36226"
                d="M-684.437,319.769c-.006-.035-.033-.04-.062-.049a1.106,1.106,0,0,1-.815-1.029c-.081-.895-.136-1.792-.2-2.688q-.171-2.335-.341-4.67-.114-1.577-.225-3.154c-.016-.224-.035-.448-.053-.672s-.018-.218.2-.218h5.328q3.547,0,7.093,0c.161,0,.191.035.179.2-.13,1.706-.248,3.413-.371,5.119q-.221,3.068-.444,6.135a1.1,1.1,0,0,1-.9,1,.473.473,0,0,0-.051-.005l-9.155,0c-.047,0-.105-.027-.14.034Zm3.732-6.255q0,2.058,0,4.116c0,.316.136.449.455.449h1.02c.331,0,.464-.131.464-.458q0-1.012,0-2.024,0-3.087,0-6.175c0-.325-.137-.46-.463-.46h-.968c-.392,0-.508.114-.508.5Q-680.706,311.491-680.705,313.514Zm-1.7.024h0q-.062-2.083-.125-4.166a.377.377,0,0,0-.412-.406q-.554,0-1.107,0a.375.375,0,0,0-.416.447q.065,1.1.132,2.209.137,2.322.274,4.643c.028.478.053.955.089,1.432a.371.371,0,0,0,.419.382c.282,0,.565,0,.847,0s.425-.137.417-.427Q-682.341,315.6-682.4,313.538Zm7.151-.031h0q.123-2.071.245-4.142a.36.36,0,0,0-.378-.4c-.369,0-.738,0-1.107,0-.309,0-.44.124-.45.437q-.076,2.411-.148,4.822-.051,1.72-.1,3.44a.371.371,0,0,0,.415.414c.2,0,.4-.008.605,0,.587.029.658-.1.688-.656C-675.41,316.119-675.328,314.814-675.252,313.508Z"
                transform="translate(686.816 -299.945)"
                fill="#1e89fd"
            />
            <path
                id="Path_36227"
                data-name="Path 36227"
                d="M-635.913,666.8c.035-.06.092-.034.14-.034l9.155,0a.492.492,0,0,1,.051.005.066.066,0,0,0,0,.031Z"
                transform="translate(638.327 -646.974)"
                fill="#1e89fd"
            />
            <path
                id="Path_36228"
                data-name="Path 36228"
                d="M-705.634,100.95a1.069,1.069,0,0,1,.641-.987c.974-.456,1.954-.9,2.931-1.352.11-.051.218-.1.329-.151.082-.034.1-.073.057-.157-.093-.185-.177-.375-.261-.564a.5.5,0,0,1,.283-.729l2.334-1.077a.5.5,0,0,1,.751.267c.084.177.171.353.244.534.043.106.086.119.19.07.635-.3,1.274-.589,1.911-.882.439-.2.877-.406,1.318-.6a1.087,1.087,0,0,1,1.468.54c.09.187.164.382.265.562.07.124-.005.142-.086.179l-4.046,1.863-4.735,2.179q-1.521.7-3.04,1.4c-.109.051-.159.039-.2-.075a7.6,7.6,0,0,1-.322-.762A1.152,1.152,0,0,1-705.634,100.95Zm6.546-4.583-.088.04q-1.1.508-2.209,1.018c-.055.026-.147.029-.094.139.091.186.175.375.26.564.028.063.062.065.121.037q1.142-.529,2.288-1.052c.072-.033.093-.069.056-.144-.086-.176-.163-.355-.248-.531C-699.016,96.405-699.022,96.353-699.088,96.367Z"
                transform="translate(705.634 -95.212)"
                fill="#1e89fd"
            />
            <path
                id="Path_36229"
                data-name="Path 36229"
                d="M-424.056,164.025l-2.469-.477c-.373-.072-.744-.149-1.118-.217-.1-.018-.135-.031-.082-.145.478-1.034.947-2.073,1.421-3.109.022-.048.021-.131.092-.127.052,0,.059.077.083.122.111.208.226.415.328.627.054.112.091.14.212.063a5.666,5.666,0,0,1,3.377-.923,5.721,5.721,0,0,1,3.133,1.095c.023.017.044.038.068.053s.055.052.034.085c-.01.015-.054.017-.079.011a4.889,4.889,0,0,0-2.256.016,5.735,5.735,0,0,0-3.157,1.864.172.172,0,0,0-.021.23C-424.347,163.46-424.209,163.731-424.056,164.025Z"
                transform="translate(437.369 -157.596)"
                fill="#1e89fd"
            />
        </svg>
    );
};

export default Delete;
