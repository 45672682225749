export const SESSIONS_LIST = `${process.env.REACT_APP_BASE_URL}/certificates/get_sessions_list`;
export const CLASS_DETAILS = `${process.env.REACT_APP_BASE_URL}/certificates/all_class_details`;
export const STUDENTS_LIST = `${process.env.REACT_APP_BASE_URL}/certificates/get_students`;
export const SCHOOL_DETAILS_BY_ID = `${process.env.REACT_APP_BASE_URL}/certificates/school_details`;
export const MENU_LIST = `${process.env.REACT_APP_BASE_URL}/certificates/menu_fields`;
export const GET_TEMPLATE_TYPES = `${process.env.REACT_APP_BASE_URL}/certificates/get_template_types`;
export const GET_TEMPLATE = `${process.env.REACT_APP_BASE_URL}/certificates/get_template`;
export const SAVE_TEMPLATE = `${process.env.REACT_APP_BASE_URL}/certificates/save_template`;
export const TEMPLATE_DETAILS = `${process.env.REACT_APP_BASE_URL}/certificates/templates`;
export const SCHOOL_LIST = `${process.env.REACT_APP_BASE_URL}/certificates/schools_list`;
export const ISSUE_DOCUMENTS = `${process.env.REACT_APP_BASE_URL}/certificates/issue_document`;
export const ISSUE_DOCUMENTS_LIST = `${process.env.REACT_APP_BASE_URL}/certificates/issued_documents`;
export const SAVE_ISSUE_DOCUMENTS = `${process.env.REACT_APP_BASE_URL}/certificates/save_issued_document`;
export const ID_TO_PDF = `${process.env.REACT_APP_BASE_URL_S3Upload}/id-to-pdf`;
export const CERTI_TO_PDF = `${process.env.REACT_APP_BASE_URL_S3Upload}/certi-to-pdf`;
export const DELETE_DOCUMENTS_LIST = `${process.env.REACT_APP_BASE_URL}`;
export const GET_STUDENTS_INFO = `${process.env.REACT_APP_BASE_URL}/pp_sync/get_student_infos`;
export const IDCARD_ISSUE_DOCUMENT = `${process.env.REACT_APP_BASE_URL}/certificates/id_card_issue_document`; // ?student_ids=&template_id=435&section_id=44860

