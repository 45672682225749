import React, { useState, useEffect } from 'react';
import { Dropdown, Typography } from '../../index';
import './IdSizeController.scss';

const pages = ['Single Vertical', 'Single Horizontal', 'Double Vertical', 'Double Horizontal'];

const IdSizeController = React.forwardRef((props, ref) => {
    const { updateDocumentSize, documentSize } = props;
    const [pageSize, setPageSize] = useState(documentSize?.format);

    const updateDocument = (pageFormateType) => {
        if (pageFormateType === 'Single Vertical') {
            return { format: 'Single Vertical', width: '5.74cm', height: '8.84cm', zoom: '100%' };
        } else if (pageFormateType === 'Single Horizontal') {
            return {
                format: 'Single Horizontal',
                width: '8.84cm',
                height: '5.74cm',
                zoom: '100%',
            };
        } else if(pageFormateType === 'Double Vertical') {
            return {
                format: 'Double Vertical',
                width: '11.48cm',
                height: '8.84cm',
                zoom: '100%',
            };
        } else{
            return{
                format: 'Double Horizontal',
                width: '17.68cm',
                height: '5.74cm',
                zoom: '100%',
            };
        }
    };

    useEffect(() => {
        if (pageSize) {
            const documentFormate = updateDocument(pageSize);
            updateDocumentSize(documentFormate);
            props.DocumentRef.current.innerHTML = '';
        }
    }, [pageSize]);

    return (
        <div className="PageSizeController">
            <Typography className="PageSizeController--Title">Id Size</Typography>
            <div className="PageSizeController--Select">
                <Dropdown
                    list={pages}
                    value={pageSize}
                    onSelect={(size) => setPageSize(size)}
                    className="PageSizeController--PageSize"
                />
            </div>
        </div>
    );
});

export default IdSizeController;
