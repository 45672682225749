import React from "react";
import { LeftArrow } from "../../../assets/icons";
import Button from "../Button/Button";
import "./PreviousButtons.scss";
const PreviousButton = (props) => {
    const { onClick, children } = props;

    return (
        <div className="PreviousButton">
            <Button variant="outlined" onClick={onClick}>
                <LeftArrow />
                <div>
                    {children}
                </div>
            </Button>
        </div>
    );
};

export default PreviousButton;
