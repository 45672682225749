export const Pathname = {
    CertificatePage: "/certificatePage",
    IdCards: "/IdCards",
    DASHBOARD: "/dashboard",
    COMPONENTS: "/components",
    COMPONENTSCERTI: "/componentsCerti",
    ISSUE_DOCUMENTS: "/issue-document",
    ISSUE_CERTIFICATE: "/issue-certificate",
    LIST_DOCUMENTS: "/list-document",
    PAGE_NOT_FOUND: "*",
    LANDING_PAGE: "/",
};
