import React from "react";

const Upload = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="28px"
      height="28px"
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
        fill="hsla(0, 1%, 36%, 1)"
        stroke="none"
      >
        <path
          d="M2256 4210 c-689 -87 -1245 -569 -1417 -1228 -14 -57 -30 -107 -35
-111 -5 -5 -31 -14 -59 -20 -211 -52 -428 -201 -560 -385 -63 -88 -130 -235
-157 -342 -18 -72 -22 -117 -22 -234 0 -175 21 -274 89 -418 131 -277 346
-455 663 -549 51 -16 136 -18 855 -21 l797 -3 0 628 0 627 -177 -176 c-159
-157 -182 -176 -220 -183 -56 -9 -125 21 -153 67 -24 39 -26 107 -6 146 8 15
154 166 324 335 332 332 336 335 419 317 30 -7 89 -61 345 -317 170 -169 316
-320 324 -335 20 -39 18 -107 -6 -146 -28 -46 -97 -76 -153 -67 -38 7 -61 26
-219 183 l-178 176 0 -627 0 -627 654 0 c534 0 669 3 739 15 445 77 809 390
952 817 122 366 69 749 -150 1078 -103 154 -295 320 -473 409 -132 66 -346
121 -474 121 -43 0 -49 3 -64 33 -9 17 -43 70 -74 117 -241 358 -613 606
-1044 696 -135 28 -395 40 -520 24z"
        />
      </g>
    </svg>
  );
};

export default Upload;
