import React, { useEffect, useState } from 'react';
import { Delete } from '../../../assets/icons';
import { Box, Typography } from '../../common';
import './DeleteController.scss';
const DeleteController = React.forwardRef((props, ref) => {
    const handleDelete = () => {
        const editor = props?.DocumentRef?.current;
        if (ref?.classList[0] == 'Line') {
            ref?.classList?.remove('lineSelected');
        }
        ref.style.display = 'none';
        console.log('EDITOR', editor);
        console.log('PARENT NODe', ref.parentNode.parentNode);
        if (ref && ref?.parentNode?.parentNode == editor) {
            editor?.removeChild(ref?.parentNode);
        }
        console.log('After Removind', editor.innerHTML);
    };

    const [isTableElement, setIsTableElement] = useState(false);

    useEffect(() => {
        // if (ref instanceof HTMLTableCellElement) {
        //     setIsTableElement(true);
        //     return;
        // }
        setIsTableElement(false);
    }, [ref]);

    return isTableElement ? (
        <></>
    ) : (
        <>
            <div className="DeleteController--Title">
                <Typography>Delete Element</Typography>
            </div>
            <Box className="DeleteController" onClick={handleDelete}>
                <div className="DeleteController--DeleteIcon">
                    <Delete />
                </div>
                <div className="DeleteController--Label"> Delete</div>
            </Box>
        </>
    );
});

export default DeleteController;
