import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import './Pagination.scss';

function Items({ currentItems }) {
    return (
        <>
            {currentItems &&
                currentItems.map((item) => (
                    <div>
                        <h3>Item #{item}</h3>
                    </div>
                ))}
        </>
    );
}

const Pagination = ({
    itemsPerPage = 4,
    currPage = 1,
    setCurrPage,
    itemOffset,
    setItemOffset,
    currentItems,
    setCurrentItems,
    total,
}) => {
    const items = Array.from({ length: total }, (_, i) => i + 1);

    // We start with an empty list of items.

    const [pageCount, setPageCount] = useState(0);
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.

    useEffect(() => {
        // Fetch items from another resources.
        const endOffset = itemOffset + itemsPerPage;
        console.log('END OFFSET', endOffset);
        console.log(`Loading items from ${itemOffset} to ${endOffset}`);
        setCurrentItems(items.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(total / itemsPerPage));
    }, [itemOffset, itemsPerPage, currPage]);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = event.selected * itemsPerPage;

        console.log(
            `User requested page number ${event.selected}, which is offset ${newOffset}`
        );
        setItemOffset(newOffset);
        setCurrPage(event.selected + 1);
    };

    return (
        <div className="PaginationBox">
            <p className="PaginationBox--Left">
                Showing {itemOffset + 1} to{' '}
                {currentItems?.length + itemOffset < total
                    ? currentItems?.length + itemOffset
                    : total}{' '}
                of {total} entries
            </p>
            <ReactPaginate
                nextLabel=">"
                onPageChange={handlePageClick}
                pageRangeDisplayed={7}
                marginPagesDisplayed={0}
                pageCount={pageCount}
                previousLabel="<"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
            />
        </div>
    );
};

export default Pagination;
