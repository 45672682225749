import React from "react";

const Line = () => {
    return (
       <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="line">
    <path d="M12,3.29a1,1,0,0,0,0,20l0-18Z"></path>
</svg>

    );
};

export default Line;
