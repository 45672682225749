import { v4 as uuidv4 } from "uuid";

const convertObjectIntoArray = (object) => {
    const convertedArray = Object.entries(object);
    return convertedArray;
};

const checkElementType = (assetName, value) => {
    const element =
        assetName === "logo" ? (
            <img 
                src={value} 
                alt='schoolLogo' 
                draggable={false}
            />
        ) : (
            <p>{value}</p>
        );

    return element;
};


const childFormateForCreatedArray = (ArrayOfMedia) =>
    ArrayOfMedia?.map((asset) => {
        const [assetName, assetElement] = asset;

        const formattedAssetChildren = {
            id: uuidv4(),
            label: assetName?.replace(/_/g, " "),
            type: assetName === "logo" ? "img" : assetName,
            element: checkElementType(assetName, assetElement),
            style: {
                fontSize: "24px",
                marginTop: "-15px", 
                height: "100%", 
                width: "100%"        
            },
        };
        return formattedAssetChildren;
    });

const childFormateForPredefinedArray = (ArrayOfMedia) =>
    ArrayOfMedia?.map((asset) => {
        const { id, name, symbol, field_type } = asset;

        const formattedAssetChildren = {
            id: id,
            label: name?.replace(/_/g, " "),
            type: field_type,
            element: checkElementType(field_type, symbol),
               style: {
                   fontSize: "14px",
                   marginTop: "75px",
               },
            variable_type: symbol ? "default" : null,
            variable_name: name,
            variable_field_type: "text box",
        };

        return formattedAssetChildren;
    });

const childFormateForTemplateType = (ArrayOfMedia) =>
    ArrayOfMedia.map((asset) => {
        const { id, name } = asset;
        const formattedAssetChildren = {
            id: id,
            label: name?.replace(/_/g, " "),
            type: typeof name,
            element: null,
            value: id,
        };

        return formattedAssetChildren;
    });

const createAssetsChild = (ArrayOfMediaAssets, isConvertedArray) => {
    if (isConvertedArray === true) {
        return childFormateForCreatedArray(ArrayOfMediaAssets);
    } else {
        return childFormateForPredefinedArray(ArrayOfMediaAssets);
    }
};

const ConvertToMediaAssets = (mediaAssetType, Assets, isTemplate) => {
    if (isTemplate) {
        const AssetChildren = childFormateForTemplateType(Assets);

        const formattedMediaAsset = {
            id: uuidv4(),
            title: mediaAssetType,
            children: AssetChildren,
        };

        return formattedMediaAsset;
    }

    const isAssetsInArray = Array.isArray(Assets);

    if (isAssetsInArray) {
        const AssetChildren = createAssetsChild(Assets, false);
        const formattedMediaAsset = {
            id: uuidv4(),
            title: mediaAssetType,
            children: AssetChildren,
        };

        return formattedMediaAsset;
    } else {
        const ArrayOfMediaAssets = convertObjectIntoArray(Assets);

        const AssetChildren = createAssetsChild(ArrayOfMediaAssets, true);

        const formattedMediaAsset = {
            id: uuidv4(),
            title: mediaAssetType,
            children: AssetChildren,
        };

        return formattedMediaAsset;
    }
};

export default ConvertToMediaAssets;
