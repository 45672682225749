import React from "react";
import "./header.scss";
import { SchoolMitraLogo } from "../../../assets/img";

const Header = () => {
    return (
        <div className="Header">
            <div className="Header--Logo">
                <img src={SchoolMitraLogo} alt="school-mitra-logo" />
            </div>
        </div>
    );
};

export default Header;
