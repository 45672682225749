import React from 'react'
import Typography from '../Typography/Typography'
import './SectionHeading.scss'

const SectionHeading = ({heading}) => {
  return (
    <div className='SectionHeading'>
        <Typography size="1.5rem">{heading}</Typography>
    </div>
  )
}

export default SectionHeading