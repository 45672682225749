import React from "react";

const LeftArrow = () => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width="10pt"
      height="12pt"
      viewBox="0 0 90.000000 90.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,90.000000) scale(0.100000,-0.100000)"
        fill="#004b85"
        stroke="none"
      >
        <path
          d="M353 636 c-79 -96 -143 -180 -143 -186 0 -6 64 -90 143 -186 l142
-174 50 2 c38 2 51 7 53 20 2 11 -49 81 -128 178 l-132 160 132 160 c79 97
130 167 128 178 -2 13 -15 18 -53 20 l-50 2 -142 -174z"
        />
      </g>
    </svg>
  );
};

export default LeftArrow;
