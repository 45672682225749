import React, { useState } from 'react';
import { Copy, Delete, Eye, Pencil } from '../../../assets/icons';
import { ConfirmDialog } from '../../index';
import Toggle from '../Toggle/Toggle';
import './Table.scss';
import FilterBar from '../Filterbar/Filterbar';
import { useMutation, useQuery, useQueryClient } from 'react-query';

const Table = ({
    columns,
    rows,
    currPage,
    itemOffset,
    currentItems,
    type,
    handleDelete,
    handleview,
}) => {
    const [open, setOpen] = useState(false);
    const queryClient = useQueryClient();

    console.log('PAGE IN TABLE ', currPage);
    console.log('ITEMS IN TABLE ', currentItems);
    console.log('OFFSET IN TABLE ', itemOffset);

    const sliced_rows =
        rows != null
            ? itemOffset < rows?.length
                ? rows.slice(itemOffset, itemOffset + currentItems.length)
                : rows
            : [];

    console.log('Sliced Rows', sliced_rows);


// i've added this
    const handleEdit = (item) => {
        console.log('Edit', item);
    };

    const handleCopy = (item) => {
        
        console.log('copy', item);
    };
 // above code is added by me   

    return (
        <>
            <table className="Table">
                <tr>
                    {columns.map((item) => (
                        <th key={item}>{item}</th>
                    ))}
                </tr>

                {sliced_rows.length > 0 ? (
                    type === 'IssueDocument' ? (
                        sliced_rows.map((item, index) => (
                            <tr key={item.id}>
                                <td width={'20px'}>{item.roll_no}</td>
                                <td width={'20px'}>{item.name}</td>
                                <td width={'20px'}>{item?.std}</td>
                                <td width={'20px'}>{item?.section}</td>
                                <td width={'20px'}>{item?.admission_number}</td>
                                <td>
                                    <div className="Table--Actions">
                                        <button
                                            onClick={() => setOpen(!open)}
                                            title="Delete"
                                        >
                                            <Delete />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))
                    ) : (
                        sliced_rows.map((item, index) => (
                            <tr key={item.id}>
                                <td width={'20px'}>{item.serialNumber}</td>
                                <td width={'20px'}>{item.name}</td>
                                <td width={'20px'}>{item?.template_type}</td>
                                <td width={'20px'}>{item?.created_on}</td>
                                <td width={'20px'}>{item?.created_by}</td>
                                <td width={'20px'}>
                                    {item?.session ? item?.session : 'NA'}
                                </td>

                                <td>
                                    <div className="Table--Actions">
                                        <button
                                            title="View"
                                            onClick={() => handleview(item)}
                                        >
                                            <Eye />
                                        </button>
                                        <button title="Edit">
                                            <Pencil />
                                        </button>
                                        <button
                                            onClick={() => setOpen(!open)}
                                            title="Delete"
                                        >
                                            <Delete />
                                        </button>
                                        <button title="Copy">
                                            <Copy />
                                        </button>
                                        <button title="On/Off">
                                            <Toggle />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))
                    )
                ) : (
                    <tr className="NoDataText--TR">
                        <h3 className="NoDataText">No Data Found</h3>
                    </tr>
                )}
            </table>
            {open && (
                <ConfirmDialog
                    message="Are you sure you want to delete?"
                    handleClose={setOpen}
                    handleDelete={handleDelete}
                />
            )}
        </>
    );
};

export default Table;
