import React, { useState } from "react";
import "./MediaAssets.scss";
import { Accordion, Divider } from "../common";

const MediaAssets = (props) => {
  const {
    className,
    Elements,
    defaultElements,
    SelectedElement,
    UpdateSelectedElement,
    updatedTemplateId,
  } = props;

  const [accordionId, setAccordionId] = useState(null);

  const customClassName = `${className} MediaAssets`;

  const handleClick = async (Element) => {
    const { element, id } = Element;
    console.log("HANDLE CLICK");
    if (element) {
      await UpdateSelectedElement([...SelectedElement, Element]);
    } else {
      updatedTemplateId(id);
    }
  };

  const handleAccordion = (id) => {
    if (id === accordionId) {
      setAccordionId(-1);
    } else {
      setAccordionId(id);
    }
  };

  const RenderIcon = (iconType, icon, style) => {
    switch (iconType) {
      case "svg":
        return icon;
      default:
        return <img src={icon} alt={iconType} style={style} />;
    }
  };

  const AccordionList = (item) => (
    <React.Fragment>
      <div
        className="Accordion--Element"
        key={item?.type}
        onClick={() => handleClick(item)}
      >
        <div className="MediaAssets--List">
          {item?.icon && (
            <div className="MediaAssets--Icon">
              {RenderIcon(item?.icon_type, item?.icon, item?.styleIcon)}
            </div>
          )}
          <p>{item?.label}</p>
        </div>
      </div>
      <Divider margin={0} />
    </React.Fragment>
  );

  const ElementsNotFound = () => (
    <React.Fragment>
      <div className="Accordion--Element">
        <p>No Elements Found.</p>
      </div>
    </React.Fragment>
  );

  const renderAccordionList = (children) =>
    children?.length > 0 ? children?.map(AccordionList) : <ElementsNotFound />;

  const renderAccordion = (elements) =>
    elements?.map((element, index) => (
      <Accordion
        title={element?.title}
        onClick={() => handleAccordion(element?.id)}
        key={index}
        isOpen={element?.id === accordionId}
        children={renderAccordionList(element?.children)}
      />
    ));

  return (
    <div className={customClassName}>
      {renderAccordion(defaultElements)}
      {renderAccordion(Elements)}
    </div>
  );
};

export default MediaAssets;
