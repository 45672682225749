import Axios from 'axios';
import { authTokenKey } from '../tokens';
import { UseLocalStorage, UseSessionStorage } from '../utils';

const authToken = UseSessionStorage('get', authTokenKey);

const axiosInitialParams = {
    headers: {
        'Content-type': 'application/json',
        'Access-Control-Allow-Origin': '*',
    },
};

const token = authToken;

const axios = Axios.create({ axiosInitialParams });

export const Get = async (url, token) => {
    const response = await axios.get(url, {
        headers: { Authorization: `Token token=${token}` },
    });
    return response.data;
};

export const Post = async (url, data) => {
    const response = await axios.post(url, data, {
        headers: { Authorization: `Token token=${token}` },
    });
    return response;
};

export const Delete = async (url) => {
    const response = await axios.delete(url, {
        headers: { Authorization: `Token token=${token}` },
    });
    return response;
};

export const Put = async (url, data) => {
    const response = await axios.put(url, data, {
        headers: { Authorization: `Token token=${token}` },
    });
    return response;
};
