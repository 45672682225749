import React from "react";
import Button from "../../common/Button/Button";
import PreviousButton from "../../common/PreviousButton/PreviousButton";
import Typography from "../../common/Typography/Typography";
import DocumentUpload from "../../DocumentUpload/DocumentUpload";
import "./CertificateFields.scss";

const Fields = [
    {
        id: 1,
        type: "logo",
        title: "Upload School logo",
        discription: "if you choose logo & edit",
    },
    {
        id: 2,
        type: "letterhead",
        title: "Upload School Letterhead",
        discription: "if you choose Letterhead & edit",
    },
    {
        id: 3,
        type: "designed-certificate",
        title: "Upload Certificate Designed",
        discription: "if you choose certificate design & edit",
    },
];
const CertificateFields = (props) => {
    const { onChange, isDocSubmit } = props;

    const handleSubmit = () => {
        isDocSubmit(true);
    };

    return (
        <div className="CertificateFields">
            <div className="CertificateFields--InputFiled">
                <h2>Certificate Name</h2>
            </div>
            <div className="CertificateFields--UploadDocuments">
                {Fields.map((field) => {
                    return (
                        <DocumentUpload
                            title={field.title}
                            subtitle={field.discription}
                            onChange={onChange}
                            key={field.id}
                        />
                    );
                })}
            </div>
            <Typography size="1.2rem" className="CertificateFields--Tip">
                First show the uploaded file and then if a user want to change
                give on option to change.
            </Typography>
            <div className="CertificateFields--Actions">
                <PreviousButton>Previous</PreviousButton>
                <Button onClick={handleSubmit}>Submit</Button>
            </div>
        </div>
    );
};

export default CertificateFields;
