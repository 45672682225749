const DownloadIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.017"
      height="20"
      viewBox="0 0 18.017 20"
    >
      <g
        id="Group_46602"
        data-name="Group 46602"
        transform="translate(638.742 -130.786)"
      >
        <path
          id="Path_44668"
          data-name="Path 44668"
          d="M-573.679,153.12l-.261-2.579a1.475,1.475,0,0,0-.367-.833l-4.657-5.216a1.473,1.473,0,0,0-1.1-.492h-5.724a1.473,1.473,0,0,0-1.014.405l-.421.4a1.474,1.474,0,0,0-.459,1.069v13.519c0,.041,0,.081,0,.122-.024.274-.057,1.732,2,1.9.039,0,.078,0,.117,0h10.422a1.474,1.474,0,0,0,1.473-1.474v-6.68A1.475,1.475,0,0,0-573.679,153.12Z"
          transform="translate(-49.184 -12.731)"
          fill="#fefefe"
        />
        <path
          id="Path_44669"
          data-name="Path 44669"
          d="M-348.072,420.565c-.205.237-.4.455-.584.674-.374.434-.75.867-1.12,1.3-.213.252-.424.506-.642.754-.374.424-.743.852-1.1,1.289-.135.165-.293.31-.412.486-.222-.255-.442-.5-.659-.758-.365-.426-.726-.856-1.1-1.278-.225-.257-.445-.52-.667-.78l-1.078-1.262c-.115-.135-.243-.261-.346-.407.029-.039.068-.02.1-.02.488,0,.977,0,1.465,0,.09,0,.112-.022.111-.112,0-1.506,0-3.012-.006-4.518a.459.459,0,0,1,.494-.495q1.676.007,3.351,0a.464.464,0,0,1,.5.494c-.01,1.494,0,2.988-.007,4.482,0,.114.017.154.145.152C-349.118,420.56-348.609,420.565-348.072,420.565Z"
          transform="translate(-272.654 -274.285)"
          fill="#f26311"
        />
        <path
          id="Path_44670"
          data-name="Path 44670"
          d="M-582.845,130.788c1.035,0,2.07,0,3.1,0a.274.274,0,0,1,.228.094c.31.342.626.678.936,1.021q.352.389.706.776c.263.287.531.57.786.863.177.2.369.392.545.6.3.352.633.683.942,1.03.238.267.482.528.722.793.261.289.519.581.79.861a.411.411,0,0,1,.12.307q-.008,1.731,0,3.461c0,.1-.022.129-.123.126-.259-.008-.52-.009-.779,0-.112,0-.129-.034-.129-.135,0-.761,0-1.522.005-2.283,0-.127-.037-.145-.151-.144-.971,0-1.942,0-2.913,0a1.842,1.842,0,0,1-.488-.038,3.411,3.411,0,0,1-1.013-.385,3,3,0,0,1-.838-.8,2.882,2.882,0,0,1-.523-1.636q-.007-1.659,0-3.317c0-.1-.016-.128-.121-.128q-2.361.006-4.722,0a1.22,1.22,0,0,0-.858.339,1.15,1.15,0,0,0-.362.88c0,.517,0,1.034,0,1.55,0,.115-.034.137-.139.134-.263-.007-.527,0-.791,0-.061,0-.093-.006-.093-.082,0-.589-.007-1.178.01-1.766a2.142,2.142,0,0,1,.509-1.323,2.08,2.08,0,0,1,1.235-.759,1.743,1.743,0,0,1,.344-.037Q-584.374,130.79-582.845,130.788Zm3.03,1.327c0,.039-.008.057-.008.076,0,1.041,0,2.082,0,3.124a1.593,1.593,0,0,0,.515,1.171,1.717,1.717,0,0,0,1.221.5c.859,0,1.717,0,2.576,0a.185.185,0,0,0,.116-.013,5.1,5.1,0,0,0-.527-.6c-.234-.269-.479-.53-.72-.794-.263-.287-.527-.573-.787-.863-.236-.263-.481-.518-.715-.783-.262-.3-.535-.588-.8-.88-.2-.216-.406-.427-.6-.648A3.66,3.66,0,0,0-579.815,132.115Z"
          transform="translate(-48.887)"
          fill="#004b85"
        />
        <path
          id="Path_44671"
          data-name="Path 44671"
          d="M-634.511,258.638c-1.213,0-2.427-.006-3.64,0a.57.57,0,0,1-.591-.6q0-1.19,0-2.379,0-.931,0-1.863a.558.558,0,0,1,.637-.637h7.221a.544.544,0,0,1,.587.581q0,2.157,0,4.314a.567.567,0,0,1-.585.587C-632.092,258.632-633.3,258.638-634.511,258.638Zm-1.044-2.752c0,.533,0,1.065,0,1.6,0,.068.009.1.09.1.288-.006.577.007.865-.005a1.263,1.263,0,0,0,.761-.2,1.335,1.335,0,0,0,.444-.731,2.844,2.844,0,0,0,.013-1.515,1.107,1.107,0,0,0-.953-.89,9.056,9.056,0,0,0-1.114-.041c-.085,0-.107.023-.106.107C-635.554,254.829-635.555,255.357-635.555,255.886Zm-2.478,0c0,.529,0,1.057,0,1.586,0,.092.025.114.113.11.164-.007.328-.007.492,0,.079,0,.1-.021.1-.1,0-.352,0-.7,0-1.057,0-.078.018-.1.1-.1a2.853,2.853,0,0,0,.657-.044.713.713,0,0,0,.481-.3,1.314,1.314,0,0,0,.178-.977.863.863,0,0,0-.869-.8c-.38-.011-.76,0-1.14-.015-.086,0-.1.024-.1.107C-638.031,254.829-638.033,255.357-638.033,255.886Zm5.214,0c0,.533,0,1.065,0,1.6,0,.078.019.1.1.1.168-.006.336-.006.5,0,.077,0,.1-.017.1-.1,0-.38,0-.761,0-1.141,0-.085.023-.109.107-.107.248.005.5,0,.745,0,.067,0,.091-.017.088-.086a3.366,3.366,0,0,1,0-.372c.006-.089-.029-.1-.108-.1-.244.005-.488,0-.733,0-.081,0-.1-.025-.1-.1,0-.228.005-.457,0-.685,0-.082.017-.109.1-.107.332.005.665,0,1,.005.1,0,.113-.032.11-.115a3.442,3.442,0,0,1,0-.372c.006-.089-.019-.114-.111-.113q-.847.008-1.693,0c-.083,0-.106.019-.106.1C-632.816,254.827-632.819,255.356-632.819,255.884Z"
          transform="translate(0 -117.91)"
          fill="#004b85"
        />
        <path
          id="Path_44672"
          data-name="Path 44672"
          d="M-577.665,424.23h-1.475q-3.333,0-6.667,0a2.061,2.061,0,0,1-1.279-.445,2.206,2.206,0,0,1-.893-1.807c0-1.887,0-3.773,0-5.66,0-.081.03-.089.1-.088.268,0,.537.006.8,0,.095,0,.12.025.12.118,0,.973,0,1.947,0,2.92,0,.893,0,1.785,0,2.678a1.16,1.16,0,0,0,.459.943,1.14,1.14,0,0,0,.75.243c2.326,0,4.653,0,6.979-.007a.4.4,0,0,1,.358.2c.028.038.055.076.086.112.2.225.4.449.591.675A.856.856,0,0,1-577.665,424.23Z"
          transform="translate(-48.904 -275.042)"
          fill="#004b85"
        />
        <path
          id="Path_44673"
          data-name="Path 44673"
          d="M-363.834,167.146a3.677,3.677,0,0,1,.269.29c.195.221.4.432.6.648.27.292.543.582.806.88.234.266.48.52.716.783.26.29.526.575.789.863.242.264.487.525.722.794a5.109,5.109,0,0,1,.528.6.186.186,0,0,1-.116.013c-.861,0-1.722,0-2.582,0a1.723,1.723,0,0,1-1.224-.5,1.591,1.591,0,0,1-.516-1.171c0-1.041,0-2.082,0-3.124C-363.842,167.2-363.838,167.185-363.834,167.146Z"
          transform="translate(-264.873 -35.031)"
          fill="#fdfdfd"
        />
        <path
          id="Path_44674"
          data-name="Path 44674"
          d="M-551.632,283.151c0-.529,0-1.057,0-1.586,0-.084.021-.109.106-.107a9.05,9.05,0,0,1,1.114.041,1.106,1.106,0,0,1,.953.89,2.844,2.844,0,0,1-.013,1.515,1.335,1.335,0,0,1-.444.731,1.263,1.263,0,0,1-.761.2c-.288.012-.576,0-.865.005-.081,0-.09-.029-.09-.1C-551.631,284.216-551.632,283.684-551.632,283.151Zm.714.013h0c0,.34,0,.68,0,1.02,0,.066.016.088.085.089.413,0,.553-.122.66-.56a2.711,2.711,0,0,0,.027-1.015c-.065-.469-.256-.645-.676-.644-.081,0-.1.025-.1.1C-550.916,282.491-550.919,282.827-550.919,283.164Z"
          transform="translate(-83.93 -145.176)"
          fill="#fdfbfb"
        />
        <path
          id="Path_44675"
          data-name="Path 44675"
          d="M-619.41,283.153c0-.529,0-1.057,0-1.586,0-.082.018-.109.1-.107.38.011.76,0,1.14.015a.863.863,0,0,1,.869.8,1.314,1.314,0,0,1-.178.977.713.713,0,0,1-.481.3,2.859,2.859,0,0,1-.657.044c-.08,0-.1.022-.1.1,0,.352,0,.7,0,1.057,0,.078-.019.1-.1.1-.164-.006-.328-.007-.492,0-.088,0-.114-.018-.113-.11C-619.408,284.211-619.41,283.682-619.41,283.153Zm.7-.645c0,.144,0,.288,0,.432,0,.038-.011.084.054.082a1.507,1.507,0,0,0,.382-.025.241.241,0,0,0,.168-.124.856.856,0,0,0,.055-.562.4.4,0,0,0-.188-.251.807.807,0,0,0-.392-.054c-.065-.006-.082.022-.081.083C-618.7,282.228-618.707,282.368-618.706,282.508Z"
          transform="translate(-18.623 -145.178)"
          fill="#fdfcfc"
        />
        <path
          id="Path_44676"
          data-name="Path 44676"
          d="M-476.786,283.136c0-.529,0-1.057,0-1.586,0-.085.023-.1.106-.1q.847.005,1.693,0c.092,0,.117.024.111.113a3.451,3.451,0,0,0,0,.372c0,.083-.013.117-.11.115-.332-.007-.665,0-1-.005-.087,0-.106.025-.1.107.006.228,0,.457,0,.685,0,.077.018.1.1.1.244-.005.489,0,.733,0,.08,0,.114.013.108.1a3.382,3.382,0,0,0,0,.372c0,.069-.02.087-.088.086-.248,0-.5,0-.745,0-.084,0-.108.022-.107.107,0,.38,0,.761,0,1.141,0,.081-.024.1-.1.1-.168-.006-.336-.006-.5,0-.08,0-.1-.021-.1-.1C-476.785,284.2-476.786,283.669-476.786,283.136Z"
          transform="translate(-156.045 -145.162)"
          fill="#fdfcfb"
        />
        <path
          id="Path_44677"
          data-name="Path 44677"
          d="M-532.1,298.864c0-.336,0-.672,0-1.008,0-.076.016-.1.1-.1.421,0,.612.175.676.644a2.711,2.711,0,0,1-.027,1.015c-.107.438-.247.564-.66.56-.069,0-.086-.023-.086-.089,0-.34,0-.68,0-1.02Z"
          transform="translate(-102.745 -160.877)"
          fill="#004b85"
        />
        <path
          id="Path_44678"
          data-name="Path 44678"
          d="M-600.133,296.822c0-.14,0-.28,0-.42,0-.061.016-.089.081-.083a.806.806,0,0,1,.392.054.4.4,0,0,1,.188.251.856.856,0,0,1-.055.562.241.241,0,0,1-.168.124,1.507,1.507,0,0,1-.382.025c-.065,0-.054-.043-.054-.082C-600.133,297.11-600.133,296.966-600.133,296.822Z"
          transform="translate(-37.196 -159.492)"
          fill="#004b85"
        />
      </g>
    </svg>
  );
};

export default DownloadIcon;
