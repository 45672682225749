import React, { useState } from "react";
import "./Accordion.scss";
import Typography from "../Typography/Typography";
import Divider from "../Divider/Divider";

const Accordion = (props) => {
    const { title, description, children } = props;
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="Accordion">
            <label className="Accordion--Label" onClick={handleToggle}>
                <Typography className="Accordion--Title">
                    {title}
                </Typography>
                <Typography className="Accordion--Icon">
                    {isOpen ? "-" : "+"}
                </Typography>
            </label>
            {isOpen && (
                <div className="Accordion--Details">
                    {children}
                    {description?.map((element, index) => (
                        <React.Fragment key={index}>
                            <div className="Accordion--Element">
                                <p>{element?.name}</p>
                            </div>
                            <Divider margin={0} />
                        </React.Fragment>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Accordion;
