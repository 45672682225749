import React from "react";
import './Select.scss'

const Select = (props) => {

  const { value, label, placeholder, children, handleChange } = props;

  return (
    <div className="SelectBox">
      <label className="SelectBox--label">{label}</label>
      <select className="SelectBox--Select" value={value} onChange={handleChange}>
        <option disabled value="" selected>{placeholder}</option>
        {children}
      </select>
    </div>
  );
};

export default Select;
