import React from 'react';
import { CustomizeVariant } from '../../../utils';
import './Input.scss';

const Input = (props) => {
    const {
        type,
        label,
        placeholder,
        className,
        style,
        onChange,
        value,
        disabled = false,
    } = props;

    const customClassName = className ? `${className} Input` : `Input`;

    return (
        <div className={customClassName}>
            {label && <label>{label}</label>}
            <input
                placeholder={placeholder}
                style={CustomizeVariant(style, { ...props })}
                onChange={onChange}
                type={type}
                value={value}
                disabled={disabled}
            />
        </div>
    );
};

export default Input;
