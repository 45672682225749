import React from "react";
import "./Modal.scss";

const Modal = (props) => {
    const { width, height, background, onClose, children, p } = props;

    const customStyle = {
        width,
        height,
        background,
        padding: p,
    };

    return (
        <div className="Modal">
            <div className="Modal--BackDrop" onClick={onClose}></div>
            <section className="Modal--Box" style={customStyle}>
                {children}
            </section>
        </div>
    );
};

export default Modal;
