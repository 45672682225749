import React from 'react';
import './Box.scss';
const Box = (props) => {
    const { children, className, ...handlers } = props;

    const customClassName = className ? `${className} Box ` : 'Box';
    return (
        <div className={customClassName} {...handlers}>
            {children}
        </div>
    );
};

export default Box;
