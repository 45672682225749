import React from 'react';
import { Button, Typography } from '../../index';
import './ConfirmDialog.scss';

const ConfirmDialog = (props) => {
    const { message, handleClose, handleDelete } = props;

    return (
        <div className="ConfirmDialog">
            <div className="ConfirmDialog--Box">
                <div className="ConfirmDialog--Box--Content">
                    <Typography size="1.6rem">{message}</Typography>
                    <Button
                        variant="outlined"
                        size="1.4rem"
                        onClick={() => handleClose(false)}
                    >
                        X
                    </Button>
                </div>
                <div className="ConfirmDialog--Box--Actions">
                    <Button variant="contained" onClick={() => handleDelete()}>
                        Yes
                    </Button>
                    <Button variant="outlined" onClick={() => handleClose(false)}>
                        No
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ConfirmDialog;
