import React from "react";
import Typography from "../common/Typography/Typography";
import Button from "../common/Button/Button";
import "./ErrorBoundary.scss";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, errorType: null };
    }
    static getDerivedStateFromError(error) {
        return { hasError: true, errorType: error };
    }

    componentDidCatch(error, errorInfo) {
        console.log(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className="ErrorBoundary">
                    <div className="ErrorBoundary--Support">
                        <Typography variant="h1" align="center" size="4rem">
                            Something went wrong !
                        </Typography>
                        <Button>Get Support</Button>
                        <Typography variant="h2" align="center">
                            {this.state.errorType.toString()}
                        </Typography>
                    </div>
                </div>
            );
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
