import React from "react";

const Table = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="35"
            height="35"
            viewBox="0 0 64 64"
            id="website-table"
        >
            <g transform="translate(0 -384)">
                <path
                    fill="#3e4f59"
                    fill-rule="evenodd"
                    d="m 9.999992,393 h 49 v 6 h -49 z"
                ></path>
                <path
                    fill="#acbec2"
                    fill-rule="evenodd"
                    d="m 9.999992,399 h 49 v 40 h -49 z"
                ></path>
                <path
                    fill="#e8edee"
                    fill-rule="evenodd"
                    d="m 9.999992,399 v 40 h 29.76953 a 28.484051,41.392605 35.599482 0 0 18.625,-40 z"
                ></path>
                <path
                    fill-rule="evenodd"
                    d="m 11.999992,392 c -1.64501,0 -3,1.355 -3,3 v 40 c 0,0.55229 0.44772,1 1,1 0.55229,0 1,-0.44771 1,-1 v -40 c 0,-0.56413 0.43587,-1 1,-1 h 45 c 0.56413,0 1,0.43587 1,1 v 3 h -42 c -0.55228,0 -1,0.44772 -1,1 0,0.55229 0.44772,1 1,1 h 42 v 37 c 0,0.56413 -0.43587,1 -1,1 h -49 c -0.55228,0 -1,0.44772 -1,1 0,0.55229 0.44772,1 1,1 h 49 c 1.64501,0 3,-1.35499 3,-3 0,-14 0,-28 0,-42 0,-1.645 -1.35499,-3 -3,-3 z"
                    color="#000"
                ></path>
                <path
                    fill="#ed7161"
                    fill-rule="evenodd"
                    d="m 54.999992,395 c -0.55228,0 -1,0.44772 -1,1 0,0.55229 0.44772,1 1,1 0.55229,0 1,-0.44771 1,-1 0,-0.55228 -0.44771,-1 -1,-1 z"
                    color="#000"
                ></path>
                <path
                    fill="#ecba16"
                    fill-rule="evenodd"
                    d="m 50.999992,395 c -0.55228,0 -1,0.44772 -1,1 0,0.55229 0.44772,1 1,1 0.55229,0 1,-0.44771 1,-1 0,-0.55228 -0.44771,-1 -1,-1 z"
                    color="#000"
                ></path>
                <path
                    fill="#42b05c"
                    fill-rule="evenodd"
                    d="m 46.999992,395 c -0.55228,0 -1,0.44772 -1,1 0,0.55229 0.44772,1 1,1 0.55229,0 1,-0.44771 1,-1 0,-0.55228 -0.44771,-1 -1,-1 z"
                    color="#000"
                ></path>
                <path
                    fill-rule="evenodd"
                    d="M4.999992 438a1 1 0 00-1 1 1 1 0 001 1 1 1 0 001-1 1 1 0 00-1-1zM12.999992 398c-.55228 0-1 .44772-1 1 0 .55229.44772 1 1 1 .55229 0 1-.44771 1-1 0-.55228-.44771-1-1-1z"
                    color="#000"
                ></path>
                <rect
                    width="32"
                    height="20"
                    x="18"
                    y="409"
                    fill="#ffa221"
                    fill-rule="evenodd"
                    rx="2"
                    ry="2"
                ></rect>
                <path
                    fill="#ffc343"
                    fill-rule="evenodd"
                    d="m 20.00976,409.8711 a 29.27639,14.051974 15 0 0 -1.714837,0.084 c -0.18632,0.30407 -0.294926,0.66072 -0.294896,1.04493 l 3e-6,16 c 1e-6,1.108 0.891998,2 2.000002,2 h 27.999996 c 1.107996,-10e-6 1.999996,-0.892 1.999996,-2 l -1e-6,-8.48828 a 29.27639,14.051974 15 0 0 -15.363272,-6.6719 29.27639,14.051974 15 0 0 -14.626962,-1.96875 z"
                ></path>
                <path
                    fill-rule="evenodd"
                    d="m 39,412 a 1,1 0 0 0 -1,1 1,1 0 0 0 1,1 h 7 a 1,1 0 0 0 1,-1 1,1 0 0 0 -1,-1 z"
                    color="#000"
                ></path>
                <path
                    fill-rule="evenodd"
                    d="M35 412c-.55228 0-1 .44771-1 1 0 .55228.44772 1 1 1 .55229 0 1-.44772 1-1 0-.55229-.44771-1-1-1zM32 412c-.55228 0-1 .44771-1 1 0 .55228.44772 1 1 1 .55229 0 1-.44772 1-1 0-.55229-.44771-1-1-1z"
                    color="#000"
                ></path>
                <rect
                    width="8"
                    height="6"
                    x="26"
                    y="417"
                    fill="#0588e2"
                    fill-rule="evenodd"
                ></rect>
                <rect
                    width="8"
                    height="6"
                    x="42"
                    y="423"
                    fill="#fe93d3"
                    fill-rule="evenodd"
                ></rect>
                <path
                    fill-rule="evenodd"
                    d="m 20,408 c -1.64469,0 -3,1.3553 -3,3 v 16 c 0,1.6447 1.35531,3 3,3 h 6 c 0.07705,0 0.127464,-0.0109 0.166016,-0.0273 A 1,1 0 0 0 27,429 v -5 h 6 v 4 h -3 c -0.552284,0 -1.000003,0.44771 -1,1 -3e-6,0.55229 0.447716,1 1,1 h 4 8 6 c 1.64469,0 3,-1.3553 3,-3 v -16 c 0,-1.6447 -1.35531,-3 -3,-3 h -2.009766 c -0.552284,0 -1.000003,0.44771 -1,1 -3e-6,0.55229 0.447716,1 1,1 H 48 c 0.571295,0 1,0.4287 1,1 v 5 h -7 -2 c -0.552283,0 -1,0.44772 -1,1 0,0.55228 0.447717,1 1,1 h 1 v 4 h -6 v -4 h 1 c 0.552283,0 1,-0.44772 1,-1 0,-0.55228 -0.447717,-1 -1,-1 h -2 -8 -7 v -5 c 0,-0.5713 0.428705,-1 1,-1 h 22.083984 c 0.552286,0 1.000003,-0.44771 1,-1 3e-6,-0.55229 -0.447714,-1 -1,-1 z m -1,10 h 6 v 4 h -6 z m 8,0 h 6 v 4 h -6 z m 16,0 h 6 v 4 h -6 z m -24,6 h 6 v 4 h -5 c -0.571295,0 -1,-0.4287 -1,-1 z m 16,0 h 6 v 4 h -6 z m 8,0 h 6 v 3 c 0,0.5713 -0.428705,1 -1,1 h -5 z"
                    color="#000"
                ></path>
            </g>
        </svg>
    );
};

export default Table;
