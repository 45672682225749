import React from "react";

const AddImage = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="23.264"
            height="20.499"
            viewBox="0 0 23.264 20.499"
        >
            <g
                id="Group_46598"
                data-name="Group 46598"
                transform="translate(583.405 -170.666)"
            >
                <path
                    id="Path_44651"
                    data-name="Path 44651"
                    d="M-582.905,184.819V173.292c.08.014.079-.047.1-.1a2.766,2.766,0,0,1,2.766-2.027q6.779,0,13.559,0a3.5,3.5,0,0,1,.665.065,2.745,2.745,0,0,1,2.186,2.718c-.021,2.776-.006,5.553-.007,8.33,0,.17-.005.339-.008.509-.062-.057-.127-.111-.187-.171q-2.091-2.1-4.177-4.2c-.141-.143-.215-.164-.37-.007q-2.707,2.743-5.426,5.473c-.127.128-.193.132-.323,0-1.233-1.244-2.476-2.477-3.709-3.721-.144-.145-.221-.161-.375-.006q-2.241,2.26-4.5,4.5C-582.771,184.72-582.814,184.8-582.905,184.819Zm8.806-5.419a1.736,1.736,0,0,0,1.728-1.716,1.734,1.734,0,0,0-1.716-1.724,1.735,1.735,0,0,0-1.73,1.713A1.741,1.741,0,0,0-574.1,179.4Z"
                    transform="translate(0 0)"
                    fill="none"
                    stroke="#707070"
                    strokeWidth="1"
                />
                <path
                    id="Path_44652"
                    data-name="Path 44652"
                    d="M-582.905,331.763c.091-.019.135-.1.193-.157q2.252-2.249,4.5-4.5c.154-.155.231-.139.375.006,1.233,1.244,2.475,2.478,3.709,3.721.13.131.2.126.323,0q2.71-2.74,5.426-5.473c.155-.157.228-.136.37.007q2.082,2.107,4.177,4.2c.06.06.124.114.187.171.055.113.017.235.038.352-.214.018-.429.025-.643.052a4.435,4.435,0,0,0-3.673,5.372,4.735,4.735,0,0,0,1.311,2.263.444.444,0,0,1-.046,0q-6.729,0-13.459,0a2.737,2.737,0,0,1-2.686-2.005c-.015-.05.012-.143-.1-.118v-.139l.033,0-.033-.042Z"
                    transform="translate(0 -147.818)"
                    fill="#acb0c3"
                />
                <path
                    id="Path_44653"
                    data-name="Path 44653"
                    d="M-582.905,545.957l.033.042-.033,0Z"
                    transform="translate(0 -358.309)"
                    fill="#050606"
                />
                <path
                    id="Path_44654"
                    data-name="Path 44654"
                    d="M-428.145,278.127a1.741,1.741,0,0,1-1.718-1.727,1.735,1.735,0,0,1,1.73-1.713,1.734,1.734,0,0,1,1.716,1.724A1.736,1.736,0,0,1-428.145,278.127Z"
                    transform="translate(-146.302 -98.968)"
                    fill="#fff"
                />
                <rect
                    id="Rectangle_5683"
                    data-name="Rectangle 5683"
                    width="8.893"
                    height="8.893"
                    rx="4.446"
                    transform="translate(-569.034 182.273)"
                    fill="#004b85"
                />
                <path
                    id="Path_44655"
                    data-name="Path 44655"
                    d="M-218.173,470.562c-.17,0-.339,0-.509,0-.342,0-.314-.05-.316.328,0,.393,0,.787-.01,1.179a.6.6,0,0,1-.443.558.667.667,0,0,1-.721-.244.715.715,0,0,1-.111-.44c0-.386-.011-.771,0-1.156.007-.19-.061-.234-.238-.229-.385.012-.771.007-1.156,0a.648.648,0,0,1-.7-.64.641.641,0,0,1,.691-.651c.4-.01.8-.009,1.2,0,.15,0,.2-.04.2-.194-.007-.424-.009-.848.008-1.272a.615.615,0,0,1,.535-.58.656.656,0,0,1,.682.372.833.833,0,0,1,.057.362c0,.355.011.71,0,1.064-.008.188.041.257.239.249.385-.015.771-.012,1.156,0a.639.639,0,0,1,.68.782.6.6,0,0,1-.553.5c-.023,0-.046,0-.069,0h-.624Z"
                    transform="translate(-344.829 -283.217)"
                    fill="#fff"
                />
            </g>
        </svg>
    );
};

export default AddImage;
